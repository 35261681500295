import React from "react";
import ContentLoader from "react-content-loader";

const TeamSkelletonLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1150}
    height={500}
    viewBox="0 0 1150 500"
    backgroundColor="#40424f33"
    foregroundColor="#5b5b5f33"
    {...props}
  >
    <rect x="5" y="5" rx="23" ry="23" width="250" height="190" />
    <rect x="270" y="5" rx="23" ry="23" width="250" height="190" />
    <rect x="535" y="5" rx="23" ry="23" width="250" height="190" />
    <rect x="800" y="5" rx="23" ry="23" width="250" height="190" />

    <rect x="5" y="210" rx="23" ry="23" width="250" height="190" />
    <rect x="270" y="210" rx="23" ry="23" width="250" height="190" />
    <rect x="535" y="210" rx="23" ry="23" width="250" height="190" />
    <rect x="800" y="210" rx="23" ry="23" width="250" height="190" />
  </ContentLoader>
);

export default TeamSkelletonLoader;
