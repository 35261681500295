import React, { useState, useEffect } from "react";
import "./CoachHomeStyles.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const CoachHome = () => {
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  const { user } = useSelector((state) => state.auth);
  const [DashboardData, setDashboardData] = useState({});

  const getDashboardData = async () => {
    try {
      const res = await axios.post(`${API_URL}/getInstituteDashboard`, {
        instituteId: user?.Institute,
      });
      setDashboardData(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDashboardData();
  }, []);
  function formatDate(dateString) {
    const options = { year: "2-digit", month: "short", day: "numeric" };
    const date = new Date(dateString);
    let daySuffix;

    switch (date.getDate()) {
      case 1:
      case 21:
      case 31:
        daySuffix = "st";
        break;
      case 2:
      case 22:
        daySuffix = "nd";
        break;
      case 3:
      case 23:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }

    return date
      .toLocaleDateString("en-US", options)
      .replace(/\d+/, (match) => match + daySuffix);
  }
  return (
    <div className="Coach_home_main">
      <h2 className="mb-5">Welcome!</h2>
      <div className="row">
        <div className="col-9 d-flex flex-column">
          <div className="box top_box d-flex align-items-start justify-content-start p-3 mb-3">
            <div className="image_box d-flex align-items-center justify-content-center me-5">
              {/* <h4>Logo</h4> */}
              <img id="inst_img" src={DashboardData?.Logo} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-evenly institute_info">
              <h5>{DashboardData?.Name}</h5>
              <h5 className="complimentary_color">{DashboardData?.State}</h5>
              <h5 className="complimentary_color">
                {DashboardData?.CurrentSeason}
              </h5>
            </div>
            <img src="images/data.png" className="data" alt="" />
          </div>
          <div className="box box_bottom d-flex flex-column p-3 mb-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex box_tile align-items-center">
                <img src="images/football.png" alt="" className="me-3" />
                <h5>Recent Practices</h5>
              </div>
              <div className="d-flex total align-items-center">
                <h5 className="me-3">Total Practices</h5>
                <h5 className="me-3 complimentary_color">
                  {DashboardData?.LatestPracticeDays?.length}
                </h5>
                <img
                  className="arrow_icon"
                  src="images/arrow-right.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="data_table">
              <div className="row mb-2">
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_heading">Date</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <div className="glowing me-2"></div>
                    <p className="table_heading">Team Name</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <div className="glowing me-2"></div>
                    <p className="table_heading">No of Players</p>
                  </div>
                </div>
              </div>
              {DashboardData?.LatestPracticeDays?.map((item) => (
                <div className="row mb-2">
                  <div className="col-4">
                    <div className="d-flex align-items-center mb-2">
                      <p className="table_data">{formatDate(item?.Date)}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex align-items-center mb-2">
                      <p className="table_data ms-2">{item?.TeamName}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex align-items-center mb-2">
                      <p className="table_data ms-5">{item?.NumberOfEntries}</p>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="row mb-2">
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_data">21th Nov, 24</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_data ms-2">Team Name</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_data ms-5">13</p>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_data">21th Nov, 24</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_data ms-2">Team Name</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_data ms-5">13</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="box box_bottom d-flex flex-column p-3 mb-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex box_tile align-items-center">
                <img src="images/helmet.png" alt="" className="me-3" />
                <h5>Recent Trainings</h5>
              </div>
              <div className="d-flex total align-items-center">
                <h5 className="me-3">Total Trainings</h5>
                <h5 className="me-3 complimentary_color">
                  {DashboardData?.LatestTrainingDays?.length}
                </h5>
                <img
                  className="arrow_icon"
                  src="images/arrow-right.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="data_table">
              <div className="row mb-2">
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="table_heading">Date</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <div className="glowing me-2"></div>
                    <p className="table_heading">Team Name</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex align-items-center mb-2">
                    <div className="glowing me-2"></div>
                    <p className="table_heading">No of Players</p>
                  </div>
                </div>
              </div>
              {DashboardData?.LatestTrainingDays?.map((item) => (
                <>
                  <div className="row mb-2">
                    <div className="col-4">
                      <div className="d-flex align-items-center mb-2">
                        <p className="table_data">{formatDate(item?.Date)}</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex align-items-center mb-2">
                        <p className="table_data ms-2">{item?.TeamName}</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex align-items-center mb-2">
                        <p className="table_data ms-5">
                          {item?.NumberOfEntries}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="col-3 d-flex flex-column align-items-center">
          <div className="side_box d-flex flex-column align-items-center mb-3 p-3">
            <div className="d-flex align-items-center justify-content-between title_side_box">
              <p>Total Players</p>
              <img src="images/total_players.png" alt="" />
            </div>
            <p className="total_number">{DashboardData?.TotalPlayers}</p>
          </div>
          <div className="side_box d-flex flex-column align-items-center mb-3 p-3">
            <div className="d-flex align-items-center justify-content-between title_side_box">
              <p>Total Coaches</p>
              <img src="images/total_coaches.png" alt="" />
            </div>
            <p className="total_number">{DashboardData?.TotalCoach}</p>
          </div>
          <div className="side_box d-flex flex-column align-items-center mb-3 p-3">
            <div className="d-flex align-items-center justify-content-between title_side_box">
              <p>Total Teams</p>
              <img src="images/total_teams.png" alt="" />
            </div>
            <p className="total_number">{DashboardData?.TotalTeams}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachHome;
