import React, { useState } from "react";
import { Input, Button, Textarea } from "@mantine/core";
import "../styles/HomePageStyles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  faAngleDown,
  faChevronDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faLocation } from "@fortawesome/free-solid-svg-icons"; // Basic location pin
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useDisclosure } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { Burger, Drawer } from "@mantine/core";
import { NativeSelect } from "@mantine/core";

import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/HomeStyles.css";
//swiper

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import { faBuilding } from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { text } from "@fortawesome/fontawesome-svg-core";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const HomePage = () => {
  const [rescipent, setrescipent] = useState("Institute"); //institute or private coaching
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const location = useLocation();

  // Function to determine if a path is active
  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const getButtonStyles = (path) => {
    return `text-lg text-gray-500 transition-colors hover:text-gray-300 pb-1 relative  ${
      isActivePath(path)
        ? 'after:content-[""] after:absolute text-white after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };

  // Common button styles for mobile navigation
  const getMobileButtonStyles = (path) => {
    return `w-full px-4 py-2 text-lg text-gray-400 transition-colors hover:text-gray-200 relative ${
      isActivePath(path)
        ? 'after:content-[""] after:absolute text-white after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };

  const [phone, setPhone] = useState("");
  const phoneStyles = {
    containerStyle: {
      ".react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus, .react-tel-input .selected-flag.open":
        {
          backgroundColor: "#111424 !important",
        },
    },
  };

  const { width } = useWindowSize();
  return (
    <div className="w-screen min-h-screen bg-[#0a0d1e] font-['Anek_Gurmukhi'] overflow-x-hidden">
      <div
        className="pt-10 pb-20"
        style={{
          backgroundImage: "url('/images/home-new2.png')",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-between px-[5%]  mb-8">
          {/* Logo */}
          <button
            onClick={() => navigate("/")}
            className="p-2 bg-transparent hover:cursor-pointer"
          >
            <img src="images/Vector.png" alt="Logo" id="top_logo" />
          </button>

          {/* Mobile Menu Button */}
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className="mr-2 lg:hidden"
            color="gray"
            size="sm"
          />

          {/* Desktop Navigation */}
          <div className="items-center hidden space-x-8 lg:flex">
            <button
              onClick={() => navigate("/practice-features")}
              className={getButtonStyles("/practice-features")}
            >
              Practice
            </button>
            <button
              onClick={() => navigate("/training-features")}
              className={getButtonStyles("/training-features")}
            >
              Training
            </button>
            <button
              onClick={() => navigate("/pricing")}
              className={getButtonStyles("/pricing")}
            >
              Pricing
            </button>{" "}
            <button
              onClick={() => navigate("/contactus")}
              className={getButtonStyles("/contactus")}
            >
              Contact us
            </button>
            <div>
              {" "}
              <Button
                onClick={() => navigate("/chooseuser")}
                color="#2FED76"
                style={{ color: "black" }}
                size="md"
                radius="md"
                fullWidth
                className="pt-1"
              >
                Sign up
              </Button>
            </div>
          </div>

          {/* Mobile Menu Drawer */}
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="xl"
            position="right"
            className="lg:hidden"
            overlayProps={{ backgroundOpacity: 0.95, blur: 4 }}
            styles={{
              content: {
                backgroundColor: "#0A0D1E",
              },
              header: {
                display: "none",
              },
              body: {
                padding: 0,
              },
            }}
          >
            <button
              className="absolute top-8 right-[5%] text-gray-500 hover:text-gray-300 mr-4"
              onClick={closeDrawer}
            >
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            </button>

            <div className="flex flex-col items-center pt-20 space-y-4">
              <button
                onClick={() => {
                  navigate("/practice-features");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/practice-features")}
              >
                Practice
              </button>
              <button
                onClick={() => {
                  navigate("/training-features");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/training-features")}
              >
                Training
              </button>
              <button
                onClick={() => {
                  navigate("/pricing");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/pricing")}
              >
                Pricing
              </button>
              <button
                onClick={() => {
                  navigate("/contactus");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/contactus")}
              >
                Contact us
              </button>

              <div className="w-[80%]">
                {" "}
                <Button
                  onClick={() => {
                    navigate("/chooseuser");
                    closeDrawer();
                  }}
                  color="#2FED76"
                  style={{ color: "black" }}
                  size="md"
                  radius="md"
                  fullWidth
                >
                  Sign up
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
        {/* Section 1 */}
        <div className="flex flex-col justify-between pl-4 lg:flex-row ">
          <div className="flex min-h-screen flex-col justify-center lg:max-w-[50%] md:pl-20">
            <h1 className="text-4xl font-semibold text-left text-white md:text-6xl">
              Full-Spectrum Analytics for Ultimate Team Success
            </h1>
            <p className="text-[#b6b7bd] font-light text-base md:text-[1.3rem] mt-4">
              Step into the Future of Football with Athlantix! Our intuitive
              platform empowers players, coaches , and parents with
              unprecedented insights for a competitive edge and a new
              perspective on the game.
            </p>
            <div className="w-56 mt-6">
              <Button
                variant="filled"
                color="#2FED76"
                style={{ color: "black" }}
                size="lg"
                radius="md"
                fullWidth
                onClick={() => navigate("/contactus")}
                className="pt-2"
              >
                Request a Demo
              </Button>
            </div>
          </div>
          {/* <img
            alt="athlantix"
            src="/images/home2.png"
            className="mt-8 md:mb-28 md:-mr-32 lg:mt-0"
          /> */}
        </div>
      </div>

      {/* Section 2 */}
      <div className="relative">
        <div className="absolute bottom-0 left-0 w-full h-40 pointer-events-none bg-gradient-to-b from-transparent to-[#050817]" />

        <div className="flex flex-col items-center justify-center pt-20 pb-20 md:pt-40">
          <div className="flex flex-col items-center justify-center px-4 md:px-10">
            <h1 className="text-4xl font-semibold text-center text-white md:text-6xl">
              Streamline Your Team's Dynamics
              <br />
              with Intuitive Management Tools
            </h1>
            <p className="text-[#b6b7bd] font-light text-base md:text-[1.3rem] text-center mt-4 max-w-[90%] lg:max-w-[70%]">
              Managing one team or multiple squads? Our tools simplify admin
              tasks, giving you more time to focus on what matters most—coaching
              and player development. Athlantix makes team management
              effortless.
            </p>
            <div className="w-64 mt-6">
              <Button
                variant="filled"
                color="#2FED76"
                style={{ color: "black" }}
                size="lg"
                radius="md"
                fullWidth
                onClick={() => navigate("/contactus")}
                className="pt-2"
              >
                Explore Athlantix Now
              </Button>
            </div>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 gap-8 px-4 mt-20 md:grid-cols-3 md:mt-32 md:px-16">
            <div className="flex flex-col text-left">
              <img alt="home" src="/images/home-2.png" className="mb-6" />
              <h2 className="pl-6 mb-4 text-4xl font-semibold text-white">
                Manage
              </h2>
              <p className="text-[rgba(255,255,255,0.4)]  max-w-[35vh] pl-6">
                Organize player and coach profiles efficiently by our efficient
                system that saves time and enhances focus.
              </p>
            </div>

            <div className="flex flex-col text-left">
              <img alt="home" src="/images/home-3.png" className="mb-6" />
              <h2 className="pl-6 mb-4 text-4xl font-semibold text-white">
                Track
              </h2>
              <p className="pl-6 text-[rgba(255,255,255,0.4)]  max-w-[35vh]">
                Ensure no aspect of the game goes unnoticed or unanalyzed as we
                help meticulously monitor every play, strategy, and outcome!
              </p>
            </div>

            <div className="flex flex-col text-left">
              <img alt="home" src="/images/home-4.png" className="mb-6" />
              <h2 className="pl-6 mb-4 text-4xl font-semibold text-white">
                Connect
              </h2>
              <p className="text-[rgba(255,255,255,0.4)]  pl-6 max-w-[35vh]">
                Break down barriers by fostering a robust communication channel
                between parents and coaches!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div
        className="flex flex-col items-center justify-center pt-20 pb-20 md:pt-64"
        style={{
          backgroundImage: "url('/images/home-bg2.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-center justify-center px-4 md:px-10">
          <h1 className="text-4xl font-semibold text-center text-white md:text-6xl">
            Empower Your Team - In-Depth
            <br />
            Insights & Analysis
          </h1>
          <p className="text-[#b6b7bd] font-light text-base md:text-[1.3rem] text-center mt-4 max-w-[90%] lg:max-w-[70%]">
            Track training and game-day performance across teams and seasons.
            Athlantix turns data into interactive charts, graphs, and real-time
            stats, providing coaches and players with the insights to excel.
          </p>
          <div className="w-64 mt-6">
            <Button
              variant="filled"
              color="#2FED76"
              style={{ color: "black" }}
              size="lg"
              radius="md"
              fullWidth
              onClick={() => navigate("/contactus")}
              className="pt-2"
            >
              Register your Institute
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center w-full mt-20 -mb-24 md:mt-40">
          <img alt="home" src="/images/home-2-2.png" className="w-full" />
        </div>
      </div>
      {/* section 4 */}
      <div className="flex flex-col items-center justify-center gap-8 px-4 mt-40 lg:flex-row md:mt-40 md:px-10 md:mx-20 lg:gap-24 xl:gap-64">
        <div className="flex flex-col justify-center max-w-2xl">
          <h1 className="text-4xl font-semibold text-left text-white md:text-6xl">
            Building Trust and
            <br />
            Belief Through Active
            <br />
            Parental Engagement
          </h1>
          <p className="text-[#b6b7bd] font-light text-base md:text-[1.3rem] mt-6">
            Be part of your child's sports journey—track every pass, goal, and
            win. Gain insights that build trust, boost confidence, and
            strengthen your connection with their team.
          </p>
          <div className="w-56 mt-8">
            <Button
              variant="filled"
              color="#2FED76"
              style={{ color: "black" }}
              size="lg"
              radius="md"
              fullWidth
              onClick={() => navigate("/contactus")}
              className="pt-2"
            >
              Request a Demo
            </Button>
          </div>
        </div>
        <div className="mt-8 lg:mt-0">
          <img
            alt="Parental Engagement"
            src="/images/home-6.png"
            className="max-w-full md:max-w-[90%] lg:max-w-full mx-auto"
          />
        </div>
      </div>

      {/* Section 5 - Insights & Analysis */}
      <div className="relative flex flex-col items-center justify-center min-h-screen pt-20 mt-20 md:pt-40 md:mt-56 md:pb-[100vh] hero-section">
        {/* .section4-background {
  background-image: url("../../public/images/landing-section4-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; 
  background-size: 100% 100%;
  width: 100%;
  height: 100%; 
} */}

        <div className="flex flex-col items-center justify-center max-w-6xl px-4 md:px-10">
          <h1 className="text-4xl font-semibold text-center text-white md:text-6xl">
            Introducing Seamless Messaging
          </h1>
          <p className="text-[#b6b7bd] font-light text-base md:text-[1.3rem] text-center mt-6 max-w-[90%] lg:max-w-[70%]">
            Athlantix enhances team communication with an integrated messaging
            system, keeping players, coaches, and staff connected in real-time.
            Available on all plans, it's key to fostering a unified, responsive
            team.
          </p>
          <p className="text-[#2FED76] font-light text-base md:text-[1.3rem] text-center mt-6 max-w-[90%] lg:max-w-[70%]">
            Boost Your Team's Connectivity!
          </p>
          <div className="w-64 mt-8">
            <Button
              variant="filled"
              color="#2FED76"
              style={{ color: "black" }}
              size="lg"
              radius="md"
              fullWidth
              onClick={() => navigate("/contactus")}
              className="pt-2"
            >
              Join Athlantix Now{" "}
            </Button>
          </div>
        </div>
        {/* <div className="w-full flex items-center justify-center max-w-[90%] xl:max-w-[80%] mx-auto">
          <img
            alt="Analytics Dashboard"
            src="/images/home-7.png"
            className="w-[70%] h-auto"
          />
        </div> */}
      </div>
      <div
        className="flex flex-col items-center justify-center px-4 pt-32 md:pt-56 md:px-10"
        style={{
          backgroundImage: "url('/images/home-bg4.png')",
          // backgroundSize: "auto",
          // backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-center justify-center max-w-4xl">
          <h1 className="text-4xl font-semibold text-center text-white md:text-6xl">
            Join Athlantix now!
          </h1>
          <p className="text-[#b6b7bd] font-light text-base md:text-[1.3rem] text-center mt-4">
            Join the Athlantix community and take control of your American
            football journey. Sign up for a free trial today!
          </p>
        </div>

        <div className="mt-16 md:mt-32 bg-[#080a19] rounded-3xl px-6 md:px-10 py-8 md:py-12 w-full max-w-[90%] md:max-w-[600px]">
          <form className="w-full">
            <div className="flex flex-col space-y-6">
              {/* Role Select */}
              <div className="relative">
                <div className="absolute left-3 top-[25px] -translate-y-1/2 text-[#3c4068] text-lg">
                  <FontAwesomeIcon icon={faBuilding} />
                </div>
                <div className="absolute right-3 top-[25px] -translate-y-1/2 text-[#3c4068] text-lg pointer-events-none">
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                <select
                  className="w-full h-12 pt-1 pl-10 text-[#3c4068] pr-10 border-2 border-[#16182f] rounded-xl bg-[#111424] text-white/50 outline-none appearance-none cursor-pointer "
                  defaultValue="Select role"
                >
                  {[
                    "Select role",
                    "Analyst",
                    "Assistant Coach",
                    "Athlete",
                    "Athletic Director",
                    "Board Member",
                    "Director",
                    "Head Coach",
                    "Manager",
                    "Office Administrator",
                    "Parent/Fan",
                    "President",
                    "Recruiter",
                    "Video Coordinator",
                  ].map((role) => (
                    <option
                      key={role}
                      value={role}
                      className="bg-[#0d0f17] text-[#3c4068]"
                    >
                      {role}
                    </option>
                  ))}
                </select>
              </div>

              {/* Name Input */}
              <div className="relative">
                <div className="absolute left-3 top-[25px] -translate-y-1/2 text-[#3c4068] text-lg">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Name"
                  className="w-full h-12 pl-10 pr-3  placeholder:text-[#3c4068] border-2 border-[#16182f]   bg-opacity-20 bg-[#393c52] text-white placeholder-[rgba(255,255,255,0.5)] outline-none rounded-xl"
                />
              </div>
              {/* Email Input */}
              <div className="relative ">
                <div className="absolute left-3 top-[25px] -translate-y-1/2 text-[#3c4068] text-lg">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full h-12 pl-10 pr-3  placeholder:text-[#3c4068] border-2 border-[#16182f]   bg-opacity-20 bg-[#393c52] text-white placeholder-[rgba(255,255,255,0.5)] outline-none rounded-xl"
                />
              </div>

              {/* Phone Input */}
              {/* Phone Input */}
              <div style={phoneStyles.containerStyle}>
                <PhoneInput
                  country={"pk"}
                  value={phone}
                  onChange={setPhone}
                  countryCodeEditable={false}
                  className="border-2 border-[#16182f] rounded-xl placeholder:text-[#3c4068]"
                  inputStyle={{
                    width: "100%",
                    height: "48px",
                    backgroundColor: "rgba(57, 60, 82, 0.2)",
                    border: "none",
                    borderRadius: "6px",
                    color: "#3c4068",
                  }}
                  buttonStyle={{
                    backgroundColor: "#111424",
                    border: "1px solid #1e2132",
                    borderLeft: "none",
                    borderTop: "none",
                    borderBottom: "none",
                    borderRadius: "6px 0 0 6px",
                    paddingRight: "2px",
                    paddingLeft: "2px",
                  }}
                  buttonClass="hover:bg-[#111424] focus:bg-[#111424]" // Add Tailwind classes for hover and focus
                  dropdownStyle={{
                    backgroundColor: "#111424",
                    color: "#3c4068",
                  }}
                  inputProps={{
                    placeholder: "090078601",
                    text: "#3c4068",
                  }}
                />
                <style>{`
            .react-tel-input .selected-flag:hover,
            .react-tel-input .selected-flag:focus,
            .react-tel-input .selected-flag.open {
              background-color: #111424 !important;
            }
            .react-tel-input .selected-flag:hover::before,
            .react-tel-input .selected-flag:focus::before,
            .react-tel-input .selected-flag.open::before {
              background-color: #111424 !important;
            }
            .react-tel-input .flag-dropdown.open .selected-flag {
              background-color: #111424 !important;
            }
          `}</style>
              </div>

              {/* Description Input */}
              <div className="relative">
                <textarea
                  name="discussion"
                  placeholder="Description"
                  rows="3"
                  class="w-full pt-2 h-auto min-h-[3rem] px-3 border-2 border-[#16182f] placeholder:text-[#3c4068] rounded-xl bg-opacity-20 bg-[#393c52] text-white placeholder-[rgba(255,255,255,0.5)] outline-none resize-none"
                ></textarea>
              </div>

              {/* Submit Button */}
              <div className="flex justify-center">
                <Button
                  variant="filled"
                  color="#2FED76"
                  type="submit"
                  className="pt-1 text-black"
                  size="md"
                  radius="md"
                >
                  Request a Demo
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col  space-y-8 bg-[#0B0D16] mt-20 px-4 md:px-20 lg:px-40 py-10">
        {/* Logo */}
        <div className="flex items-center md:ml-40">
          <img
            src="images/new-logo-2.png"
            alt="Athlantix Logo"
            className="h-12"
          />
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 md:ml-40">
          {/* Contact Information */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">Texas</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">+21 325 62351 423</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">contact@athlantix.com</p>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col space-y-4">
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Home
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Features
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Pricing
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Testimonials
            </p>
          </div>

          {/* Additional Contact Information */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">Texas</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">+21 325 62351 423</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">contact@athlantix.com</p>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="flex justify-center pt-4 border-t border-[#2a2c35]">
          <p className="text-[#b5b6b9] text-lg">
            Copyright © 2024 Athlantix. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
