import React, { useEffect, useState } from "react";
import "./SearchInstStyles.scss";
import { Input, TextInput } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "@mantine/core";
import { toast } from "react-toastify";
import SignupCoachbyinst from "../pages/SignupCoachbyinst";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal } from "@mantine/core";
import axios from "axios";
const SearchCoach = ({ hideSidebar = () => {} }) => {
  const { user } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);

  const [searchValue, setSearchValue] = useState("");
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/search`;

  const [searched, setSearched] = useState(false);

  const SearchPlayer = () => {
    // TextSearch();
    // close();
    // console.log(searchValue);
    if (searchValue.trim() !== "") {
      close();
      addcoachbymail();
    }
  };
  const addcoachbymail = async () => {
    try {
      const response = await axios.post(
        `${port}/api/users/Institute/addCoachByEmail`,
        {
          instituteId: user._id,
          email: searchValue,
        }
      );
      console.log(response.data);
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Coach added to Institute</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const [SearchResults, setSearchResults] = useState([]);
  // const TextSearch = async () => {
  //   try {
  //     const response = await axios.post(`${API_URL}/textSearchCoach`, {
  //       text: searchValue,
  //     });
  //     console.log(response.data);
  //     setSearchResults(response.data);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setSearched(true);
  //   }
  // };
  const navigate = useNavigate();

  const [Signup, setSignup] = useState(false);

  // const sidebarhidehelper = () => {};

  return (
    <>
      {!Signup && (
        <div className=" px-4 py-3">
          <div className="Search_cont d-flex align-items-center justify-content-center">
            <div className="outter_search_box d-flex justify-content-center align-items-center">
              <div className="inner_search_box d-flex flex-column align-items-center justify-content-center">
                <img src="images/vector.png" alt="" className="mb-4" />
                <h6 className="mb-4">Check Registered Coaches</h6>
                <Button variant="filled" color="green" onClick={open}>
                  Check Coaches
                </Button>
              </div>
              <div className="inner_search_box d-flex flex-column align-items-center justify-content-center">
                <img src="images/vector.png" alt="" className="mb-4" />
                <h6 className="mb-4">Add New Coaches</h6>
                <Button
                  variant="filled"
                  color="green"
                  onClick={() =>
                    // navigate("/signupcoachbyinst", {
                    //   state: { instituteId: user._id },
                    // })
                    {
                      hideSidebar();
                      setSignup(true);
                    }
                  }
                >
                  Add Coaches
                </Button>
              </div>
            </div>
          </div>

          {/* {searched == true && (
        <div className="d-flex align-items-center justify-content-start flex-wrap">
          {SearchResults &&
            SearchResults?.map((player, index) => (
              <AddPlayer player={player} key={index} />
            ))}
        </div>
      )} */}
          <Modal
            opened={opened}
            onClose={close}
            withCloseButton={false}
            centered
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
          >
            <TextInput
              size="lg"
              radius="md"
              placeholder="Add Coach Email"
              label="Add Coach Email"
              value={searchValue}
              className="mt-5"
              onChange={(e) => setSearchValue(e.target.value)}
              leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            />
            <div className="d-flex align-items-center justify-content-center pt-4 mt-5">
              <Button variant="filled" color="green" onClick={SearchPlayer}>
                Search
              </Button>
            </div>
          </Modal>
        </div>
      )}
      {Signup && (
        <SignupCoachbyinst
          Finished={() => {
            setSignup(false);
            hideSidebar();
          }}
        />
      )}
    </>
  );
};

const AddPlayer = ({ player }) => {
  const { user } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const addtoteam = async (player) => {
    console.log(user);
    try {
      const response = await axios.post(
        `${port}/api/users/Institute/addCoach`,
        {
          instituteId: user._id,
          coachId: player._id,
        }
      );
      console.log(response.data);

      console.log(player);
    } catch (err) {
      console.log(err);
    } finally {
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Request sent to Coach</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
      >
        <div className="d-flex flex-column align-items-center justify-content-between my-5">
          <h5 className="mb-3">Are you sure you want to delete this player?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={close}
            >
              Decline
            </Button>
            <Button variant="filled" color="rgba(163, 2, 2, 1)" onClick={close}>
              Accept
            </Button>
          </div>
        </div>
      </Modal>
      <div className="m-2">
        <div className="Player">
          {/* <CloseButton
            size="lg"
            variant="transparent"
            className="close_button"
            onClick={open}
          /> */}
          <div className="row">
            <div className="col-6">
              <img src={player?.Image} alt="" />
            </div>
            <div className="col-6 pt-4">
              <h5>{player?.firstName + " " + player?.lastName}</h5>
              {/* <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2"
                color="#009B3A"
              />
              <p>Registered</p>
            </div> */}
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Position</p>
                <p className="value">Forward right winger</p>
              </div>
              <div className="d-flex align-items-center mt-2 ">
                <p className="metric me-3">Weight</p>
                <p className="value">{player?.instituteName}</p>
              </div>
              <div className="button_cont d-flex align-items-center justify-content-center mt-4">
                <Button
                  variant="filled"
                  color="rgba(57, 60, 82, 0.2)"
                  size="sm"
                  radius="xl"
                  className="me-3"
                  onClick={() => addtoteam(player)}
                >
                  Add Coach
                </Button>
                {/* <FontAwesomeIcon icon={faBookmark} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCoach;
