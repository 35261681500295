import React, { useEffect, useState } from "react";
import "../styles/DashboardStyles.scss";
import UserDashboard from "../components/UserDashboard";
import { Button } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../auth/authslice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Settings from "../components/Settings";
import Messaging from "../components/Messaging";
import PlayerRequests from "../components/PlayerRequests";
import PlayerTraining from "../components/PlayerTraining";
import PlayerPractice from "../components/PlayerPractice";
import StudentVideoEditor from "../components/StudentVideoEditor";
import {
  faHouse,
  faFutbol,
  faPeopleGroup,
  faSignal,
  faGear,
  faArrowRightFromBracket,
  faMessage,
  faVideo,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@mantine/core";
import { Menu } from "@mantine/core";
const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/loginplayer");
  };
  useEffect(() => {
    if (!user) navigate("/loginplayer");
    console.log(user);
  }, [user]);

  const [activeIcon, setActiveIcon] = useState("Dashboard");

  //notifications
  const port = process.env.REACT_APP_IP_ADDRESS;
  const [requests, setrequests] = useState([]);
  // const [Player, setPlayer] = useState({});
  const getrequests = async () => {
    const response = await axios.post(
      `${port}/api/Request/viewIncomingRequests`,
      {
        id: user?._id,
      }
    );
    console.log(response.data);
    setrequests(response.data);
  };

  const approveRequest = async (request, status) => {
    try {
      const response = await axios.post(
        `${port}/api/Request/respondToRequest`,
        {
          requestId: request?._id,
          response: status,
        }
      );
      console.log(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      getrequests();
    }
  };
  useEffect(() => {
    console.log("dashboard----------", user);
  }, [user]);

  return (
    <div className="dashboard_cont">
      {!user?.InstituteID && (
        <div className="confirmation_cont d-flex flex-column align-items-center justify-content-center">
          <img src="/images/new-logo.png" alt="" id="logo" />
          <img src="images/thankyou_player.png" alt="" className="gray_scale" />
          <h1 className="mb-5">Thank you for Signing up</h1>
          <h4 className="mb-5">
            Your details are being verified and your institute will need to add
            you. <br />
            <br />
            <br />
            Thank you for choosing Athlantix{" "}
          </h4>
          <Button
            variant="outline"
            radius="md"
            className="mb-2"
            color="green"
            onClick={() => {
              dispatch(logout());
            }}
          >
            Go back home
          </Button>
        </div>
      )}
      {user?.InstituteID && (
        <>
          {user?.isPaid ? (
            <>
              <div className="sidebar">
                <div className="sidebar_logo d-flex  align-items-center justify-content-center pt-4">
                  <img
                    src="/images/new-logo.png"
                    alt=""
                    className="me-1"
                    onClick={() => setActiveIcon("Dashboard")}
                  />
                </div>
                <div className="sidebar_cont   d-flex flex-column justify-content-between ">
                  <div className="sidebar_cont_top d-flex flex-column justify-content-between">
                    <div
                      className={`icon_cont ${
                        activeIcon === "Dashboard" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Dashboard")}
                    >
                      <FontAwesomeIcon icon={faHouse} />
                      <p>Dashboard</p>
                    </div>
                    <div
                      className={`icon_cont ${
                        activeIcon === "Training" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Training")}
                    >
                      <FontAwesomeIcon icon={faFutbol} />
                      <p>Training</p>
                    </div>
                    <div
                      className={`icon_cont ${
                        activeIcon === "Practice" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Practice")}
                    >
                      <FontAwesomeIcon icon={faPeopleGroup} />
                      <p>Practice</p>
                    </div>
                    <div
                      className={`icon_cont ${
                        activeIcon === "Video" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Video")}
                    >
                      <FontAwesomeIcon icon={faVideo} />
                      <p>Video</p>
                    </div>
                    <div
                      className={`icon_cont ${
                        activeIcon === "Message" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Message")}
                    >
                      <FontAwesomeIcon icon={faMessage} />
                      <p>Message</p>
                    </div>
                    <div
                      className={`icon_cont ${
                        activeIcon === "Requests" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Requests")}
                    >
                      <FontAwesomeIcon icon={faBell} />
                      <p>Requests</p>
                    </div>

                    <div
                      className={`icon_cont ${
                        activeIcon === "Settings" ? "active_nav" : ""
                      }`}
                      onClick={() => setActiveIcon("Settings")}
                    >
                      <FontAwesomeIcon icon={faGear} />
                      <p>Settings</p>
                    </div>
                  </div>
                  <div className="sidebar_cont_bottom">
                    {" "}
                    <div
                      className="icon_cont"
                      onClick={() => dispatch(logout())}
                    >
                      <FontAwesomeIcon icon={faArrowRightFromBracket} />{" "}
                      <p>Logout</p>
                    </div>
                  </div>
                </div>
              </div>
              {activeIcon === "Message" && (
                <div className="dasboard_main px-4 py-3 d-flex align-items-center justfy-content-end">
                  <Messaging />
                </div>
              )}
              {(activeIcon === "Dashboard" ||
                activeIcon === "Requests" ||
                activeIcon === "Training" ||
                activeIcon === "Practice" ||
                activeIcon === "Video") && (
                <div className="dashboard_content px-4 py-3">
                  <div
                    className={`main_top d-flex align-items-center ${
                      activeIcon == "Dashboard"
                        ? " justify-content-between "
                        : "justify-content-end"
                    }`}
                  >
                    {activeIcon == "Dashboard" && (
                      <div className="welcome_text_cont px-4 py-2 d-flex align-items-center">
                        <h3 className="me-3">Welcome back!</h3>
                        <img src="images/Hand_Shake.png" alt="" />
                      </div>
                    )}
                    <div className="main_top_right d-flex justify-content-between align-items-center ">
                      <div className="notification_cont">
                        <Menu closeOnItemClick={false} position="bottom-end">
                          <Menu.Target>
                            <FontAwesomeIcon
                              icon={faBell}
                              onClick={() => getrequests()}
                              size="xl"
                            />
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Label>
                              <div className="dropdown_header d-flex align-items-center justify-content-start ">
                                <h6>
                                  {" "}
                                  {`Notifications (${requests?.length})`}
                                </h6>
                              </div>
                            </Menu.Label>
                            {requests &&
                              requests?.map((item, index) => (
                                <Menu.Item className="mb-3" key={index}>
                                  <div className="notifications_design"></div>
                                  <div className="notification d-flex flex-column px-3 ">
                                    {item?.type !== "Notification" ? (
                                      <>
                                        {" "}
                                        <h6
                                          style={{ padding: "0", margin: "0" }}
                                        >
                                          {item?.institute?.name}
                                        </h6>
                                        <p
                                          style={{ padding: "0", margin: "0" }}
                                        >
                                          {item?.institute?.name} has invited
                                          you to be play for them
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <h6
                                          style={{ padding: "0", margin: "0" }}
                                        >
                                          {item?.senderName}
                                        </h6>
                                        <p
                                          style={{ padding: "0", margin: "0" }}
                                        >
                                          {item?.message.substring(0, 50) +
                                            (item?.message.length > 50
                                              ? "..."
                                              : "")}
                                        </p>
                                      </>
                                    )}
                                    <div className="mt-2 d-flex justify-content-center align-items-center button_cont">
                                      {item?.type !== "Notification" && (
                                        <Button
                                          variant="gradient"
                                          gradient={{
                                            from: "#009B3A",
                                            to: "#2FED76",
                                            deg: 90,
                                          }}
                                          style={{ color: "black" }}
                                          className="me-2"
                                          radius={"lg"}
                                          size="xs"
                                          onClick={() =>
                                            approveRequest(item, true)
                                          }
                                          classNames="NotificationButton"
                                        >
                                          Accept
                                        </Button>
                                      )}

                                      <Button
                                        variant="filled"
                                        color="#C8102E"
                                        size="xs"
                                        radius={"lg"}
                                        onClick={() =>
                                          approveRequest(item, false)
                                        }
                                        classNames="NotificationButton"
                                      >
                                        {item?.type == "Notification"
                                          ? "Remove"
                                          : "Reject"}
                                      </Button>
                                    </div>
                                  </div>
                                </Menu.Item>
                              ))}
                            {!requests?.length && (
                              <div className="no_notifications_cont d-flex align-items-center justify-content-center">
                                <h4 className="text-center">
                                  No Notifications Found
                                </h4>
                              </div>
                            )}
                          </Menu.Dropdown>
                        </Menu>
                      </div>
                      <Avatar color="cyan" radius="xl">
                        <img className="user_image" src={user?.Image} alt="" />
                      </Avatar>
                    </div>
                  </div>
                  {activeIcon === "Dashboard" && <UserDashboard />}
                  {activeIcon === "Requests" && <PlayerRequests />}
                  {activeIcon === "Training" && <PlayerTraining />}
                  {activeIcon === "Practice" && <PlayerPractice />}
                  {activeIcon === "Video" && <StudentVideoEditor />}
                </div>
              )}

              {activeIcon === "Settings" && <Settings type="Player" />}
            </>
          ) : (
            <>
              <div className="confirmation_cont d-flex flex-column align-items-center justify-content-center">
                <img src="/images/new-logo.png" alt="" id="logo" />
                <img
                  src="images/payment_failed.png"
                  alt=""
                  className="gray_scale"
                />
                <h1 className="mb-5">Institute Access Blocked</h1>
                <h4 className="mb-5">
                  Your access has been blocked. Please check back later or
                  contact your Institute
                  <br /> if the problem persists.
                </h4>
                <Button
                  variant="outline"
                  radius="md"
                  className="mb-2"
                  color="green"
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Go back home
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
