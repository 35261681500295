import React, { useEffect, useState } from "react";
import "./RegisteredPlayerStyles.scss";
import { Input, TextInput } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { CloseButton } from "@mantine/core";
import TeamSkelletonLoader from "./TeamSkelletonLoader";
import { toast } from "react-toastify";

import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mantine/core";
import axios from "axios";
import SearchInst from "./SearchInst";

const RegisteredPlayers = ({ hideSidebar = () => {} }) => {
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  useEffect(() => {
    getPlayers();
  }, []);
  const [players, setPlayers] = useState([]);

  const [loading, setLoading] = useState(true);

  const [addPlayerModal, { open: openAddPlayer, close: closeAddPlayer }] =
    useDisclosure(false);

  const [newPlayerEmail, setNewPlayerEmail] = useState("");
  const [emailSending, setEmailSending] = useState(false); // State to manage the loading state of the email button

  const [successModal, { open: openSuccessModal, close: closeSuccessModal }] =
    useDisclosure(false);

  const getPlayers = async (resetTeam) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/getAllPlayersFromInstitute`,
        {
          instituteId: user?._id,
        }
      );
      console.log("all players from inst", response.data);
      setPlayers(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      if (resetTeam) {
        selectTeam(SelectedTeam);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (players?.length > 0) {
      setSearchPlayers(true);
    }
  }, [players]);
  const [searchPlayers, setSearchPlayers] = useState(false);
  const [team, setteam] = useState([]);
  const [teamName, setteamName] = useState("");
  const [SelectedTeam, setSelectedTeam] = useState("");
  const selectTeam = (team) => {
    console.log("Select Team", team);
    setSelectedTeam(team);
    setteam(team?.players);
    setteamName(team?.name);
  };

  const RefreshAfterplayerSignup = () => {
    console.log("Refresh players");
    setLoading(true);
    getPlayers(true);
  };

  const [formValues, setFormValues] = useState({
    name: "",
    password: "",
    phonenumber: "",
    email: "",
    playerid: "",
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [modalopen, setmodalopen] = useState(false);
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const addprenthelper = (playerid) => {
    setFormValues({
      ...formValues,
      playerid: playerid,
    });
    open();
  };
  const handleSubmit = () => {
    addParent();
  };
  const [addparentLoading, setaddparentLoading] = useState(false);
  const addParent = async () => {
    console.log(formValues);
    setaddparentLoading(true);
    try {
      const response = await axios.post(
        `${port}/api/users/Parent/createParent`,
        {
          name: formValues.name,
          password: formValues.password,
          phonenumber: formValues.phonenumber,
          email: formValues.email,
          playerid: formValues.playerid,
        }
      );
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Parent Added Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in adding Parent</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setFormValues({
        name: "",
        password: "",
        phonenumber: "",
        email: "",
        playerid: "",
      });
      setaddparentLoading(false);
      close();

      setparentexist(true);
    }
  };
  const [parentexist, setparentexist] = useState(true);
  // const [parent, setparent] = useState({});
  const getparentbyemail = async () => {
    console.log(formValues);
    try {
      const response = await axios.post(
        `${port}/api/users/Parent/getParentByEmail`,
        {
          email: formValues.email,
        }
      );
      if (response.status == 200) {
        // setparent(response.data);
        addSibling(response.data);
      }
    } catch (error) {
      console.log(error);
      setparentexist(false);
    }
  };
  const addSibling = async (ExistingParent) => {
    setaddparentLoading(true);
    try {
      const response = await axios.post(`${port}/api/users/Parent/addSibling`, {
        parentid: ExistingParent._id,
        playerid: formValues.playerid,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Parent Added Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in adding Parent</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setaddparentLoading(false);
      close();
      setparentexist(true);
      setFormValues({
        name: "",
        password: "",
        phonenumber: "",
        email: "",
        playerid: "",
      });
    }
  };
  // useEffect(() => {
  //   if (parent) {
  //     addSibling();
  //   }
  // }, [parent]);
  const [removeplayerid, setremoveplayerid] = useState("");

  //remove player

  const [playerremovemode, setplayerremovemode] = useState(false);

  const removeplayerhelper = (playerid) => {
    if (playerremovemode) {
      setremoveplayerid(playerid);
      setmodalopen(true);
    }
  };
  const removePlayer = async () => {
    try {
      const response = await axios.post(
        `${port}/api/users/Institute/RemovePlayer`,
        {
          instituteId: user._id,
          playerId: removeplayerid,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setremoveplayerid("");
      setmodalopen(false);
      getplayersbyteam();
      setplayerremovemode(false);
      getPlayers();
    }
  };

  const getplayersbyteam = async () => {
    try {
      const response = await axios.post(
        `${port}/api/users/Institute/getPlayersbyTeamName`,
        {
          InstituteID: user._id,
          TeamName: teamName,
        }
      );
      console.log(response.data);
      setteam(response.data?.players);
    } catch (error) {
      console.log(error);
    }
  };
  const goback = () => {
    if (teamName) {
      setteamName("");
      setteam([]);
      setSearchPlayers(true);
    }
  };

  //migrate player
  const [migratemodel, setmigratemodel] = useState(false);
  const [playerNewTeam, setplayerNewTeam] = useState();
  const [playertomigrate, setplayertomigrate] = useState();

  const migrateplayerhelper = (player) => {
    setplayertomigrate(player);
    setmigratemodel(true);
  };

  const migrateTeam = async () => {
    try {
      setmigratemodel(false);
      const response = await axios.post(
        `${port}/api/users/Institute/MoveToTeam`,
        {
          PlayerId: playertomigrate,
          InstituteId: user._id,
          ToTeamName: playerNewTeam,
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      getplayersbyteam();
      getPlayers();
      setplayerNewTeam();
      setplayertomigrate();
    }
  };
  const [isSidebarHidden, setisSidebarHidden] = useState(false);
  const helpsidebarhide = () => {
    hideSidebar();
    setisSidebarHidden(!isSidebarHidden);
  };
  useEffect(() => {
    if (!isSidebarHidden) {
      getPlayers();
    }
  }, [isSidebarHidden]);

  const resetForm = () => {
    setNewPlayerEmail("");
  };

  const addPlayer = async () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(newPlayerEmail)) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" alt="Logo" />
          <p className="mt-3">Please enter a valid email address</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return; // Stop execution if the email is invalid
    }
    setEmailSending(true); // Start loading before the request

    try {
      const response = await axios.post(
        `${API_URL}/checkifuserexists`,
        { email: newPlayerEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.exists) {
        showErrorToast(response);
        setEmailSending(false);

        return;
      }

      const createResponse = await axios.post(
        `${API_URL}/createSignUpLinkPlayer`,
        {
          email: newPlayerEmail,
          instituteId: user._id,
          teamName: teamName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      getPlayers();
      setEmailSending(false);

      closeAddPlayer();
      resetForm();
      openSuccessModal(); // Show the success modal after a successful request
    } catch (err) {
      console.error(err);
      setEmailSending(false);

      handleCloseAddPlayer(); // Close the modal and reset the form on error
      showErrorToast(
        err.response || "An error occurred while sending the invitation."
      );
    }
  };
  const showErrorToast = (response) => {
    const message =
      response && response.data && response.data.message
        ? response.data.message
        : "An unexpected error occurred"; // Fallback message if no specific message found

    toast.error(
      <>
        <img src="images/new-logo.png" className="toast_logo" alt="Logo" />
        <p className="mt-3">{message}</p>
      </>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };
  const handleCloseAddPlayer = () => {
    closeAddPlayer();
    resetForm();
  };

  return (
    <div
      className={`dashboard_content px-4 py-3 ${
        isSidebarHidden && "no_sidebar_cont"
      }`}
    >
      <div className="main_top d-flex justify-content-end align-items-center">
        {/* <div className="input_cont">
          <Input
            size="md"
            radius="xl"
            placeholder="Search"
            leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          />
        </div> */}
        <div className="main_top_right d-flex justify-content-between align-items-center ">
          <div className="notification_cont">
            <FontAwesomeIcon icon={faBell} size="xl" />
          </div>
          <Avatar color="cyan" radius="xl">
            {/* <img src="images/team-logo.png" alt="" /> */}
            <img className="user_image" src={user?.Image} alt="" />
          </Avatar>
        </div>
      </div>
      <div className="Registered_players_cont">
        <div className="register_top d-flex align-items-center justify-content-between mt-4 mb-3">
          <div className="d-flex align-items-center">
            {teamName && !isSidebarHidden && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_icon"
                onClick={goback}
              />
            )}
            {teamName && !isSidebarHidden && <h5>Registered Players</h5>}
            {!teamName && <h5>Choose Team to add player</h5>}
          </div>
          {teamName && searchPlayers && (
            <div>
              <Button
                variant="outline"
                size="md"
                color="green"
                leftSection={<FontAwesomeIcon icon={faCirclePlus} />}
                // onClick={() => setSearchPlayers(false)}
                onClick={openAddPlayer}
                className="me-3"
              >
                Add Players
              </Button>
              {team.length > 0 && (
                <Button
                  variant={playerremovemode ? "filled" : "outline"}
                  size="md"
                  color="red"
                  onClick={() => setplayerremovemode(!playerremovemode)}
                >
                  Delete Players
                </Button>
              )}
            </div>
          )}
        </div>
        {!loading && searchPlayers && !teamName && (
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            {players.map((player) => (
              <div
                className="Team_box m-2 d-flex flex-column align-items-center justify-content-center"
                onClick={() => selectTeam(player)}
              >
                <img src={user?.Image} alt="" />
                <div className="design_box d-flex align-items-center justify-content-center">
                  <h4>{player?.name}</h4>
                </div>
              </div>
            ))}
          </div>
        )}
        {!loading && searchPlayers && teamName && (
          <div className="d-flex align-items-start justify-content-start flex-wrap">
            {team && team.length === 0 ? (
              <div className="empty-players-container">
                <img src="images/Empty-player.png" alt="No coaches available" />
              </div>
            ) : (
              team?.map((player) => (
                <div className="m-2">
                  <div
                    className={`Player ${
                      playerremovemode ? "delete_player" : ""
                    }`}
                    onClick={() => removeplayerhelper(player._id)}
                  >
                    <div className="row">
                      <div className="col-6">
                        <img src={player?.Image} alt="" className="ms-3" />
                      </div>
                      <div className="col-6 pt-4">
                        <h5>{player?.firstName + " " + player?.lastName}</h5>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="me-2"
                            color="#009B3A"
                          />
                          <p>Registered</p>
                        </div>
                        <div className="d-flex align-items-center mt-2 "></div>
                        <div className="d-flex align-items-center mt-2 "></div>
                        <div className="button_cont d-flex align-items-center mt-2">
                          <Button
                            variant="filled"
                            color="rgba(57, 60, 82, 0.2)"
                            size="xs"
                            radius="xl"
                            className="me-3"
                            onClick={() => migrateplayerhelper(player?._id)}
                          >
                            Move Player
                          </Button>
                          <Button
                            variant="filled"
                            color="rgba(57, 60, 82, 0.2)"
                            size="xs"
                            radius="xl"
                            className="me-3"
                            onClick={() => addprenthelper(player._id)}
                          >
                            Add parent
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
        {!loading && !searchPlayers && (
          <SearchInst
            teamName={teamName}
            hideSidebar={() => helpsidebarhide()}
            RefreshAfterplayerSignup={() => RefreshAfterplayerSignup()}
          />
        )}
        {loading && <TeamSkelletonLoader />}
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size="lg"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        {parentexist && (
          <>
            <TextInput
              label="Email"
              name="email"
              placeholder="Input placeholder"
              onChange={handleChange}
              radius="md"
              size="lg"
              className="mt-3"
            />
            <div className="d-flex align-items-center justify-content-center pt-5 mt-5">
              <Button
                variant="filled"
                color="green"
                onClick={getparentbyemail}
                loading={addparentLoading}
              >
                Submit
              </Button>
            </div>
          </>
        )}
        {!parentexist && (
          <>
            <TextInput
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleChange}
              radius="md"
              size="lg"
              className="mb-3"
              required
            />

            <TextInput
              label="Password"
              name="password"
              placeholder="Enter Password"
              onChange={handleChange}
              radius="md"
              size="lg"
              className="mb-3"
              required
            />
            <TextInput
              label="Phone Number"
              name="phonenumber"
              placeholder="Enter Phone Number"
              onChange={handleChange}
              radius="md"
              size="lg"
              className="mb-3"
              required
            />
            <div className="d-flex align-items-center justify-content-center ">
              <Button
                variant="filled"
                color="green"
                onClick={handleSubmit}
                loading={addparentLoading}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Modal>
      <Modal
        opened={modalopen}
        onClose={() => setmodalopen(false)}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="remove_player_modal"
      >
        <div className="d-flex flex-column align-items-center justify-content-between my-5">
          <h5 className="mb-3">Are you sure you want to delete this player?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={() => setmodalopen(false)}
            >
              Decline
            </Button>
            <Button
              variant="filled"
              color="rgba(163, 2, 2, 1)"
              onClick={removePlayer}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        opened={migratemodel}
        onClose={() => setmigratemodel(false)}
        centered
        size="xl"
        className="date_modal"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="training_modal"
      >
        <div className="main_modal_cont d-flex flex-column  justify-content-center">
          <>
            <div className="modal_cont d-flex align-items-center justify-content-center">
              <div className="dt_cont">
                <h5>Choose New Team</h5>
                <div className="d-flex align-items-start justify-content-start flex-wrap">
                  {players.map((currteam, index) => {
                    if (currteam?.name == teamName) return;
                    else
                      return (
                        <div
                          className=" m-1"
                          key={index}
                          onClick={() => setplayerNewTeam(currteam?.name)}
                        >
                          <div
                            className={`player ${
                              playerNewTeam == currteam?.name && "active"
                            } px-2 d-flex align-items-center flex-column justify-content-center`}
                          >
                            <img src="images/team-logo.png" alt="" />
                            <p>{currteam?.name}</p>
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          </>

          <div className="d-flex align-items-center  justify-content-center mt-5 ">
            <Button variant="outline" color="green" onClick={migrateTeam}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      {/* two new models */}

      <Modal
        opened={addPlayerModal}
        onClose={handleCloseAddPlayer}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="add_coach_modal_cont p-4">
          <div className="d-flex justify-content-center">
            {" "}
            <h2 style={{ marginBottom: "3vw" }}>Send Invitation to Player</h2>
          </div>

          <div style={{ marginBottom: "3vw" }}>
            <Input
              size="lg"
              radius="xl"
              placeholder="Enter Player Email"
              value={newPlayerEmail}
              onChange={(e) => setNewPlayerEmail(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4">
            <Button
              variant="outline"
              color="gray"
              className="me-2"
              onClick={handleCloseAddPlayer}
              size="lg"
              radius="lg"
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              color="green"
              onClick={addPlayer}
              size="lg"
              radius="lg"
              loading={emailSending}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        opened={successModal}
        onClose={closeSuccessModal}
        withCloseButton={false}
        centered
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="success_modal_cont d-flex flex-column align-items-center justify-content-center my-5">
          <img
            src="images/invitation-success.png"
            alt="Success"
            className="success-image "
          />
          <h3 className="mb-5">Your invitation is successfully sent.</h3>
          <Button
            color="black"
            size="xl"
            radius="lg"
            onClick={closeSuccessModal}
            variant="white"
          >
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RegisteredPlayers;
