import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { redirect } from "react-router-dom";

const Pricing = () => {
  const monthly = process.env.STRIPE_PROUCT_MONTHLY;
  const yearly = process.env.STRIPE_PROUCT_YEARLY;
  const [plan, setplan] = useState();
  const getCheckoutSession = async () => {
    try {
      console.log("monthly", "price_1OYttaAy4xElr583SHuyqusY");
      const res = await axios.post(
        `https://Athlantix.com/api/users/Institute/createPayment`,
        {
          planid: "price_1OYttaAy4xElr583SHuyqusY",
          Inst_id: "65a65e81851dd046b9a275f2",
          quantity: 4,
        }
      );
      console.log(res);
      window.open(res.data.url); // Open new browser tab with res.url
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button onClick={() => getCheckoutSession()}>Monthly</button>
      <button onClick={() => getCheckoutSession()}>Yearly</button>
    </div>
  );
};

export default Pricing;
