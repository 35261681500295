import React from "react";
import "./SearchResultStyles.scss";
import { Button } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { RingProgress, Text } from "@mantine/core";
const SearchResult = ({ result }) => {
  return (
    <div className="search_result ">
      <div className="row">
        <div className="col-5">
          <div className="team d-flex px-3   py-2 align-items-center">
            <img
              src="https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/atl.png&w=80&h=80&scale=crop&cquality=40&location=origin"
              alt=""
            />
            <p className="ps-2">
              {/* {result ? result.instituteName : "Houston Texas"} */}
              Atlanta Falcons
            </p>
          </div>
          <img
            id="player_image"
            src={result?.Image ? result?.Image : "images/player_profile.png"}
            alt=""
          />
        </div>
        <div className="player_info col-5 d-flex flex-column pt-5">
          <h3>
            {result
              ? result.firstName + " " + result.lastName
              : "Blake Cashman"}
          </h3>
          <div className="player_data d-flex justify-content-start align-items-center">
            <p className="title">Position</p>
            <p className="stat">Quater Back</p>
          </div>
          <div className="player_data d-flex justify-content-start align-items-center">
            <p className="title">College</p>
            <p className="stat">
              {result ? result.instituteName : "Central State College"}
            </p>
          </div>
          <div className="player_data d-flex justify-content-start align-items-center">
            <p className="title">Nationality</p>
            <p className="stat">American</p>
          </div>
          <div className="player_data d-flex justify-content-start align-items-center">
            <p className="title">Nationality</p>
            <p className="stat">American</p>
          </div>
          <div className="button_cont d-flex align-items-center mt-4">
            <Button variant="outline" color="gray" className="me-3">
              Check Profile
            </Button>
            <FontAwesomeIcon icon={faBookmark} size="xl" color="#ffffff" />
          </div>
        </div>
        <div className="col-2 pt-3">
          <div className="stat_cont d-flex flex-column ">
            <div className="ring_stat d-flex flex-column align-items-center">
              <RingProgress
                thickness={3}
                size={45}
                sections={[
                  {
                    value: result ? result?.metricsID?.kickAccuracy * 5 : 75,
                    color: "#009B3A",
                  },
                ]}
                rootColor="rgba(255, 255, 255, 0.05)"
                label={
                  <Text c="#009B3A" fw={500} ta="center" size="sm">
                    {result ? result?.metricsID?.kickAccuracy * 5 : "75"}%
                  </Text>
                }
              />
              <p>Accuracy</p>
            </div>
            <div className="ring_stat d-flex flex-column align-items-center">
              <RingProgress
                thickness={3}
                size={45}
                sections={[
                  {
                    value: result ? result?.metricsID?.endurance * 5 : 75,
                    color: "#009B3A",
                  },
                ]}
                rootColor="rgba(255, 255, 255, 0.05)"
                label={
                  <Text c="#009B3A" fw={500} ta="center" size="sm">
                    {result ? result?.metricsID?.endurance * 5 : "75"}%
                  </Text>
                }
              />
              <p>Endurance</p>
            </div>
            <div className="ring_stat d-flex flex-column align-items-center">
              <RingProgress
                thickness={3}
                size={45}
                sections={[
                  {
                    value: result ? result?.metricsID?.kickPower * 5 : 75,
                    color: "#009B3A",
                  },
                ]}
                rootColor="rgba(255, 255, 255, 0.05)"
                label={
                  <Text c="#009B3A" fw={500} ta="center" size="sm">
                    {result ? result?.metricsID?.kickPower * 5 : "75"}%
                  </Text>
                }
              />
              <p>Power</p>
            </div>
            <div className="ring_stat d-flex flex-column align-items-center">
              <RingProgress
                thickness={3}
                size={45}
                sections={[
                  {
                    value: result ? result?.metricsID?.puntAccuracy * 5 : 75,
                    color: "#009B3A",
                  },
                ]}
                rootColor="rgba(255, 255, 255, 0.05)"
                label={
                  <Text c="#009B3A" fw={500} ta="center" size="sm">
                    {result ? result?.metricsID?.puntAccuracy * 5 : "75"}%
                  </Text>
                }
              />
              <p> Punting</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
