import "./PracticeStyles.scss";
import React, { useState, useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { Button, NumberInput, Input, Textarea } from "@mantine/core";
import { Progress } from "@mantine/core";
import { RingProgress, Text } from "@mantine/core";
import DataTable from "react-data-table-component";
import { Center } from "@mantine/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TeamSkelletonLoader from "../components/TeamSkelletonLoader";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const Practice = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [rederInput, setRenderInput] = useState(false);
  const [stage, setStage] = useState(1);
  const [position, setposition] = useState(0);
  const [selectedDate, setselectedDate] = useState(dayjs());
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState("");
  const [description, setDescription] = useState("");
  const [Practice, setPractice] = useState([]);
  const [datedelete, setdatedelete] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const { user } = useSelector((state) => state.auth);
  const nextposition = () => {
    if (position < 1) {
      setposition(position + 1);
    }
    if (position == 1 && team != "") {
      close();
      AddPracticeDay();
    }
  };
  useEffect(() => {
    if (!opened) {
      setTeam("");
      setselectedDate(dayjs());
      setposition(0);
    }
    if (opened) {
      setdatedelete(false);
    }
  }, [opened]);
  const prevposition = () => {
    if (position > 0) {
      setposition(position - 1);
    }
  };
  useEffect(() => {
    getTeams();
    getPracticeDays();
  }, []);
  const getTeams = async () => {
    try {
      const res = await axios.post(
        `${API_URL}users/Institute/getAllTeamsForInstitute`,
        {
          instituteId: user?.Institute,
        }
      );

      setTeams(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const AddPracticeDay = async () => {
    try {
      const res = await axios.post(`${API_URL}Practice/addPracticeDay`, {
        Date: selectedDate,
        Desc: description,
        TeamName: team,
        InstituteId: user?.Institute,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Practice Added Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in adding Practice</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      getPracticeDays();
    }
  };
  const getPracticeDays = async () => {
    try {
      setloading(true);
      const res = await axios.post(
        `${API_URL}Practice/getAllCurrentPractices`,
        {
          InstituteId: user?.Institute,
        }
      );
      setPractice(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };
  const [loading, setloading] = useState(false);
  const DeletePracticeDay = async (id) => {
    try {
      const res = await axios.post(`${API_URL}Practice/removePracticeDay`, {
        PracticeDayId: id,
        InstituteId: user?.Institute,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Practice Deleted Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in deleting Practice</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      getPracticeDays();
    }
  };

  const Trainingdatehelper = (currdate) => {
    setCurrentDate(currdate);
    if (datedelete) {
      DeletePracticeDay(currdate?._id);
    } else {
      setStage(2);
      getplayersbyteam(currdate?.TeamName);
    }
  };

  //stage 2
  const [chosenTeamPlayers, setchosenTeamPlayers] = useState([]);
  const [practiceDeleteMode, setpracticeDeleteMode] = useState(false);
  const [choosePlayerModal, setchoosePlayerModal] = useState(false);
  const [selectedPlayer, setselectedPlayer] = useState({});
  const [choosePlayerModalPosition, setchoosePlayerModalPosition] = useState(0);
  const [playerPosition, setplayerPosition] = useState();
  const [playerSubPosition, setplayerSubPosition] = useState();
  const positions = [
    { GeneralPos: "Quarter Back", SubPos: [] },
    { GeneralPos: "Running Back", SubPos: [] },
    { GeneralPos: "Halfback", SubPos: [] },
    { GeneralPos: "Wide Receiver", SubPos: [] },
    { GeneralPos: "Tight End", SubPos: [] },
    { GeneralPos: "Offensive Line", SubPos: ["Center", "Guard", "Tackle"] },
    {
      GeneralPos: "Defensive Line",
      SubPos: [
        "Defensive End",
        "Defensive Tackle",
        "Nose Tackle",
        "Outside Linebacker",
      ],
    },
    {
      GeneralPos: "Linebackers",
      SubPos: ["Inside Linebacker", "Middle Linebacker"],
    },
    {
      GeneralPos: "Defensive Backs",
      SubPos: ["Cornerback", "Free Safety", "Strong Safety"],
    },
    {
      GeneralPos: "Special Teams",
      SubPos: [
        "Kicker",
        "Punter",
        "Long Snapper",
        "Holders",
        "Gunners",
        "Kick Returner",
        "Punt Returner",
        "Upback",
        "Personal Protector",
      ],
    },
    {
      GeneralPos: "Hybrid Positions",
      SubPos: ["H-Back", "Slotback", "Wingback", "Rover", "Monster"],
    },
  ];

  useEffect(() => {
    if (!choosePlayerModal) {
      setchoosePlayerModalPosition(0);
      setselectedPlayer({});
      setplayerPosition();
      setplayerSubPosition();
    }
  }, [choosePlayerModal]);

  const chooseplayerNextposition = () => {
    if (choosePlayerModalPosition == 0 && selectedPlayer) {
      setchoosePlayerModalPosition(choosePlayerModalPosition + 1);
    }
    if (choosePlayerModalPosition == 1 && playerPosition?.SubPos?.length == 0) {
      // setchoosePlayerModalPosition(choosePlayerModalPosition + 1);
      getStats(playerPosition?.GeneralPos, null);
      setStage(3);
      close();
    }
    if (choosePlayerModalPosition == 1 && playerPosition?.SubPos?.length > 0) {
      setchoosePlayerModalPosition(choosePlayerModalPosition + 1);
    }
    if (choosePlayerModalPosition == 2 && playerSubPosition) {
      getStats(playerPosition?.GeneralPos, playerSubPosition);
      setStage(3);
      close();
    }
  };
  const chooseplayerPrevposition = () => {
    if (choosePlayerModalPosition > 0) {
      setchoosePlayerModalPosition(choosePlayerModalPosition - 1);
    }
  };
  const addplayerPractice = async () => {
    const statsObject = stats.reduce((obj, item) => {
      obj[item.name] = item.value;
      return obj;
    }, {});
    try {
      const res = await axios.post(`${API_URL}Practice/addPracticeObj`, {
        PlayerId: selectedPlayer?._id,
        GeneralPos: playerPosition?.GeneralPos,
        SubPos: playerSubPosition ? playerSubPosition : null,
        Stats: statsObject,
        PracticeDayId: currentDate?._id,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Practice Data saved Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      setCurrentDate(res.data);
      setchoosePlayerModal(false);
      setselectedPlayer();
      setplayerPosition();
      setplayerSubPosition();
      setStage(2);
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error saving Practice data </p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  const [stats, setstats] = useState();
  const getStats = async (generalpos, subpos) => {
    try {
      const res = await axios.post(`${API_URL}Practice/getPositionAndStats`, {
        GeneralPos: generalpos,
        subPos: subpos,
      });

      setstats(
        res.data?.map((item, index) => ({
          name: item,
          value: 0, // or any other numeric value you want to assign
          edit: true,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getplayersbyteam = async (teamName) => {
    try {
      const response = await axios.post(
        `${port}/api/users/Institute/getPlayersbyTeamName`,
        {
          InstituteID: user?.Institute,
          TeamName: teamName,
        }
      );
      console.log(response.data);
      setchosenTeamPlayers(response.data?.players);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("choseteamPlayers", chosenTeamPlayers);
    }
  };

  useEffect(() => {
    console.log(playerPosition);
  }, [playerPosition]);

  const columns = [
    {
      name: "Name",
      selector: "Name",
    },
    {
      name: "GP",
      selector: "GP",
    },
    {
      name: "Att",
      selector: "Att",
    },
    {
      name: "Yds",
      selector: "Yds",
    },
    {
      name: "C%",
      selector: "CPercent",
    },
    {
      name: "Avg",
      selector: "Avg",
    },
    {
      name: "Y/G",
      selector: "YPerG",
    },
    {
      name: "TD/G",
      selector: "TDPerG",
    },
    {
      name: "Lng",
      selector: "Lng",
    },
    {
      name: "QB Rating",
      selector: "QBRating",
    },
  ];
  const data = [
    {
      Name: "Michael Johnson",
      GP: 8,
      Att: 220,
      Yds: 950,
      CPercent: 62,
      Avg: 4.3,
      YPerG: 118.75,
      TDPerG: 1.1,
      Lng: 55,
      QBRating: 87.3,
    },
    {
      Name: "John Smith",
      GP: 12,
      Att: 180,
      Yds: 800,
      CPercent: 58,
      Avg: 4.4,
      YPerG: 66.67,
      TDPerG: 0.9,
      Lng: 42,
      QBRating: 83.6,
    },
    {
      Name: "James Williams",
      GP: 10,
      Att: 210,
      Yds: 1100,
      CPercent: 65,
      Avg: 5.5,
      YPerG: 110,
      TDPerG: 1.0,
      Lng: 48,
      QBRating: 91.7,
    },
    {
      Name: "Robert Brown",
      GP: 9,
      Att: 195,
      Yds: 920,
      CPercent: 61,
      Avg: 4.7,
      YPerG: 102.22,
      TDPerG: 1.2,
      Lng: 50,
      QBRating: 88.9,
    },
    {
      Name: "William Davis",
      GP: 11,
      Att: 240,
      Yds: 1050,
      CPercent: 63,
      Avg: 4.4,
      YPerG: 95.45,
      TDPerG: 0.9,
      Lng: 52,
      QBRating: 86.2,
    },
    {
      Name: "Richard Johnson",
      GP: 10,
      Att: 215,
      Yds: 980,
      CPercent: 64,
      Avg: 4.6,
      YPerG: 98.0,
      TDPerG: 1.1,
      Lng: 47,
      QBRating: 89.4,
    },
    {
      Name: "Thomas Anderson",
      GP: 13,
      Att: 260,
      Yds: 1200,
      CPercent: 66,
      Avg: 4.6,
      YPerG: 92.31,
      TDPerG: 1.0,
      Lng: 51,
      QBRating: 90.1,
    },
    {
      Name: "Charles Taylor",
      GP: 8,
      Att: 190,
      Yds: 890,
      CPercent: 59,
      Avg: 4.7,
      YPerG: 111.25,
      TDPerG: 1.2,
      Lng: 44,
      QBRating: 87.8,
    },
    {
      Name: "Daniel Martinez",
      GP: 14,
      Att: 280,
      Yds: 1400,
      CPercent: 68,
      Avg: 5.0,
      YPerG: 100.0,
      TDPerG: 1.1,
      Lng: 58,
      QBRating: 92.5,
    },
    {
      Name: "Matthew Thompson",
      GP: 15,
      Att: 290,
      Yds: 1350,
      CPercent: 67,
      Avg: 4.7,
      YPerG: 90.0,
      TDPerG: 1.0,
      Lng: 46,
      QBRating: 88.0,
    },
  ];
  const [currentplayerPractice, setcurrentplayerPractice] = useState(); //current date player practice
  const [confirmationModal, setconfirmationModal] = useState(false);
  const [deletedateConfirmation, setdeletedateConfirmation] = useState(false);
  const [updatePractice, setupdatePractice] = useState(false);
  const PlayerPracticeHelper = (currplayer) => {
    setcurrentplayerPractice(currplayer);
    if (practiceDeleteMode) {
      setconfirmationModal(true);
    } else {
      console.log("current player", currplayer);
      setplayerPosition({ GeneralPos: currplayer?.GeneralPosition });
      setplayerSubPosition(currplayer?.SubPosition);
      setstats(
        Object.entries(currplayer?.Stats)?.map(([name, value]) => ({
          name,
          value,
          edit: false,
        }))
      );
      setupdatePractice(true);
      setStage(3);
    }
  };
  useEffect(() => {
    if (deletedateConfirmation && practiceDeleteMode) {
      deleteplayerPractice();
    }
  }, [deletedateConfirmation]);

  const deleteplayerPractice = async () => {
    setconfirmationModal(false);
    setpracticeDeleteMode(false);
    try {
      const res = await axios.post(`${API_URL}Practice/removePracticeObj`, {
        PracticeObjId: currentplayerPractice?._id,
        PracticeDayId: currentDate?._id,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Practice deleted Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      setCurrentDate(res.data);
      setcurrentplayerPractice();
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Deleting Practice</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
    }
  };
  const UpdatePractice = async () => {
    try {
      const res = await axios.post(`${API_URL}Practice/updatePracticeObj`, {
        PracticeObjId: currentplayerPractice?._id,
        GeneralPos: playerPosition?.GeneralPos,
        SubPos: playerSubPosition ? playerSubPosition : null,
        Stats: stats.reduce((obj, item) => {
          obj[item.name] = item.value;
          return obj;
        }, {}),
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Practice Data Updated Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error Updating Practice Data </p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setupdatePractice(false);
      setStage(2);
      setcurrentplayerPractice();
      setstats();
      setplayerPosition();
      setplayerSubPosition();
    }
  };
  const addpracticehelper = () => {
    if (updatePractice) {
      UpdatePractice();
    } else {
      addplayerPractice();
    }
  };
  return (
    <div className="Training_cont pt-3">
      {stage == 1 && (
        <>
          <div className="training_top d-flex align-items-center justify-content-between">
            <h3>Data Entry Dates</h3>
            <div>
              <Button
                variant="outline"
                color="green"
                onClick={open}
                className="me-3"
              >
                Add Date
              </Button>
              <Button
                variant={datedelete ? "filled" : "outline"}
                color="red"
                onClick={() => setdatedelete(!datedelete)}
              >
                Delete Date
              </Button>
            </div>
          </div>
          {!loading && Practice?.SeasonPracticeDays?.length > 0 && (
            <div className="training_main mt-5">
              <div className="date_cont mt-3 px-3 d-flex flex-wrap">
                {Practice?.SeasonPracticeDays?.map((currdate, index) => {
                  const date = new Date(currdate?.Date);
                  const formattedDate = date.toISOString().split("T")[0];
                  const monthYear =
                    date.toLocaleString("default", { month: "long" }) +
                    ", " +
                    date.getFullYear().toString().substr(-2);
                  const dayOfWeek = date.toLocaleString("default", {
                    weekday: "long",
                  });
                  const day = date.getDate();
                  return (
                    <div
                      className={`${
                        datedelete ? "delete_box" : "date_box "
                      } d-flex flex-column align-items-center justify-content-center m-2`}
                      onClick={() => Trainingdatehelper(currdate)}
                    >
                      <div className="design_box"></div>
                      <div className="date_inner d-flex  align-items-start justify-content-start px-4">
                        <div className="d-flex flex-column align-items-start justify-content-center">
                          <p>{monthYear}</p>
                          <h1>{day}</h1>
                          <p>{dayOfWeek}</p>
                        </div>

                        <div className="card"></div>
                        <div className="card"></div>
                        <div className="card d-flex align-items-center justify-content-center">
                          <h4>{currdate?.PlayerPracticeStats?.length}</h4>
                        </div>
                      </div>
                      <div className="design_box"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!loading && Practice?.SeasonPracticeDays?.length == 0 && (
            <>
              <div className="empty d-flex flex-column align-items-center justify-content-start">
                <img src="images/no_player.png" alt="" className="grayscale" />
                <h2>Maximize Your Practice Efforts!</h2>
                <h4>
                  Practice is essential for player development and team success.
                  Let's create <br /> your first practice session to optimize
                  your team's performance.
                </h4>
                <Button
                  variant="outline"
                  color="green"
                  className="me-3 mt-4"
                  onClick={open}
                >
                  Add Date
                </Button>
              </div>
            </>
          )}
          {loading && <TeamSkelletonLoader />}

          <Modal
            opened={opened}
            onClose={close}
            centered
            size="xl"
            id="parctice_modal"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
          >
            <div className="main_modal_cont d-flex flex-column  justify-content-between">
              {position == 0 && (
                <div className="modal_cont d-flex align-items-center justify-content-center">
                  <div className="dt_cont">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker
                        defaultValue={dayjs()}
                        value={selectedDate}
                        onChange={(newValue) => setselectedDate(newValue)}
                        sx={{
                          ".MuiDateCalendar-root": {
                            color: "#ffffff",
                            padding: "5%",
                            borderRadius: "16px",
                            backgroundColor: "rgba(57, 60, 82, 0.2)",
                          },
                          ".MuiPickersToolbar-root": {
                            color: "#ffffff",

                            backgroundColor: "#101222",
                          },
                          ".MuiPickersCalendarHeader-root": {
                            backgroundColor: "rgba(57, 60, 82, 0.2)",
                            borderRadius: "16px",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                          },
                          ". MuiPickersLayout-root": {
                            backgroundColor: "#101222",
                            color: "#ffffff",
                          },
                          ".MuiPickersLayout-contentWrapper": {
                            backgroundColor: "#101222",
                            color: "#ffffff",
                          },
                          ".MuiDialogActions-root": {
                            backgroundColor: "#101222",
                            color: "#ffffff",
                            display: "none",
                          },
                          ".MuiDayCalendar-weekDayLabel": {
                            color: "#ffffff",
                          },
                          ".MuiPickersDay-root": {
                            color: "#ffffff",
                            backgroundColor: "#101222",
                          },
                          ".MuiButtonBase-root": {
                            color: "#ffffff",
                          },
                          ".Mui-selected": {
                            backgroundColor: "#009B3A !important",
                          },
                          ".MuiPickersToolbar-content": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}
              {position == 1 && (
                <>
                  <div className="modal_cont d-flex align-items-center justify-content-center">
                    <div className="dt_cont">
                      <Input.Wrapper
                        label="Description"
                        description=""
                        className="mb-4"
                        size="md"
                        value={description}
                        onChange={(e) => setDescription(e.target.value.trim())}
                      >
                        <Textarea
                          variant="filled"
                          size="lg"
                          radius="md"
                          name="email"
                        />
                      </Input.Wrapper>
                      <h5>Teams</h5>
                      <div className="d-flex align-items-start justify-content-start flex-wrap">
                        {teams?.map((currteam, index) => (
                          <div
                            className=" m-1"
                            key={index}
                            onClick={() => setTeam(currteam)}
                          >
                            <div
                              className={`player ${
                                team == currteam && "active"
                              } px-2 d-flex align-items-center flex-column justify-content-center`}
                            >
                              <img src="images/team-logo.png" alt="" />
                              <p>{currteam}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="d-flex align-items-center  justify-content-end mt-3">
                <Button
                  variant="outline"
                  color="grey"
                  className="me-2"
                  onClick={prevposition}
                >
                  Back
                </Button>
                <Button variant="outline" color="green" onClick={nextposition}>
                  Next
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}

      {stage == 2 && (
        <>
          <div className="training_top d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_arrow"
                onClick={() => {
                  getPracticeDays();
                  setStage(1);
                }}
              />
              <h3>
                {new Date(currentDate?.Date).getDate()}{" "}
                {new Date(currentDate?.Date).toLocaleString("default", {
                  month: "long",
                })}{" "}
                {new Date(currentDate?.Date).getFullYear()}
              </h3>
            </div>
            <div>
              <Button
                variant="outline"
                color="green"
                onClick={() => setchoosePlayerModal(true)}
                className="me-3"
              >
                Add player
              </Button>
              <Button
                variant={practiceDeleteMode ? "filled" : "outline"}
                color="red"
                onClick={() => setpracticeDeleteMode(!practiceDeleteMode)}
              >
                Delete Practice
              </Button>
            </div>
          </div>
          <h3 className="mt-3">{currentDate?.TeamName}</h3>
          <div className="d-flex flex-column align-items-center justify-content-start mt-4 ">
            {currentDate?.PlayerPracticeStats?.length > 0 &&
              currentDate?.PlayerPracticeStats?.map((currplayer, index) => (
                <>
                  <div
                    className={`player_practice d-flex align-items-center justify-content-between mb-3 ${
                      practiceDeleteMode && "player_practice_delete"
                    }`}
                    onClick={() => PlayerPracticeHelper(currplayer)}
                  >
                    <div className="design_box"></div>
                    <img src={currplayer?.PlayerID?.Image} alt="" />
                    <div className="player_info d-flex flex-column pt-2  ms-4 ">
                      <h3 className="mb-3">
                        {currplayer?.PlayerID?.firstName +
                          " " +
                          currplayer?.PlayerID?.lastName}
                      </h3>
                      <div className="fullwidth d-flex align-items-center justify-content-between top_info ">
                        <p className="complimentary">Centeral State College</p>

                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src="images/barchart.svg"
                              alt=""
                              className="me-2"
                            />
                            <p className="contrast">
                              {currplayer?.GeneralPosition}
                            </p>
                          </div>
                          <>
                            {currplayer?.SubPosition && (
                              <div className="d-flex align-items-center">
                                <img
                                  src="images/barchart.svg"
                                  alt=""
                                  className="me-2"
                                />
                                <p className="contrast">
                                  {currplayer?.SubPosition}
                                </p>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="fullwidth d-flex align-items-center justify-content-start mt-3 player_stats">
                        <div className="d-flex flex-column  me-5">
                          <p className="complimentary">WEIGHT</p>
                          <p>{currplayer?.PlayerID?.weight} lbs</p>
                        </div>
                        <div className="d-flex flex-column ">
                          <p className="complimentary">HEIGHT</p>
                          <p>
                            {currplayer?.PlayerID?.feet +
                              "ft " +
                              currplayer?.PlayerID?.inches +
                              '"'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="jesrsey_number d-flex align-items-center justify-content-center ">
                      <p>{currplayer?.PlayerID?.jerseyNumber}</p>
                    </div>
                  </div>
                </>
              ))}
            {currentDate?.PlayerPracticeStats?.length == 0 && (
              <div className="empty_2 d-flex flex-column align-items-center justify-content-start">
                <img src="images/no_player.png" alt="" className="grayscale" />
                <h2>Maximize Your Practice Efforts!</h2>
                <h4>
                  Practice is essential for player development and team success.
                  Let's create <br /> your first practice session to optimize
                  your team's performance.
                </h4>
              </div>
            )}
          </div>
          <Modal
            opened={choosePlayerModal}
            onClose={() => setchoosePlayerModal(false)}
            centered
            size="xl"
            id="choose_player_modal"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
          >
            <div className="main_modal_cont d-flex flex-column  justify-content-between">
              <>
                <div className="modal_cont d-flex align-items-center justify-content-center">
                  <div className="choose_player_pos_cont">
                    {choosePlayerModalPosition == 0 && (
                      <>
                        <h5 className="mb-5">Choose Player</h5>
                        <div className="d-flex align-items-start justify-content-start flex-wrap">
                          <>
                            {chosenTeamPlayers?.map((player, index) => (
                              <div
                                className="m-2"
                                key={index}
                                onClick={() => setselectedPlayer(player)}
                              >
                                <div
                                  className={`player_card  px-2 d-flex  align-items-center justify-content-start   ${
                                    selectedPlayer == player && "activePlayer"
                                  }`}
                                >
                                  <img
                                    src="images/player-1.png"
                                    alt=""
                                    className="me-2"
                                  />
                                  <p>{player?.firstName}</p>
                                </div>
                              </div>
                            ))}
                          </>
                        </div>
                      </>
                    )}
                    {choosePlayerModalPosition == 1 && (
                      <>
                        <h5 className="mb-5">Choose Position</h5>
                        <div className="d-flex align-items-start justify-content-start flex-wrap">
                          <>
                            {positions?.map((currpos, index) => (
                              <div
                                className={`${
                                  playerPosition?.GeneralPos ==
                                    currpos?.GeneralPos && "position_active"
                                } position m-2 d-flex flex-column align-items-center justify-content-center`}
                                onClick={() => setplayerPosition(currpos)}
                              >
                                <img src="images/football.png" alt="" />
                                <p>{currpos?.GeneralPos}</p>
                              </div>
                            ))}
                          </>
                        </div>
                      </>
                    )}
                    {choosePlayerModalPosition == 2 && (
                      <>
                        <h5 className="mb-5">Choose Sub-Position</h5>
                        <div className="d-flex align-items-start justify-content-start flex-wrap">
                          <>
                            {playerPosition?.SubPos?.map((currpos, index) => (
                              <div
                                className={`${
                                  playerSubPosition == currpos &&
                                  "position_active"
                                } position m-2 d-flex flex-column align-items-center justify-content-center`}
                                onClick={() => setplayerSubPosition(currpos)}
                              >
                                <img src="images/football.png" alt="" />
                                <p>{currpos}</p>
                              </div>
                            ))}
                          </>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>

              <div className="d-flex align-items-center  justify-content-end mt-3 ">
                <Button
                  variant="outline"
                  color="grey"
                  className="me-2 mb-2"
                  onClick={chooseplayerPrevposition}
                >
                  Back
                </Button>
                <Button
                  variant="outline"
                  className="mb-2"
                  color="green"
                  onClick={chooseplayerNextposition}
                >
                  Next
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}

      {stage == 3 && (
        <>
          <div className="stats_heading d-flex align-items-center justify-content-between px-4 mb-2">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_arrow"
                onClick={() => setStage(2)}
              />
              <h3>
                {playerPosition?.GeneralPos}
                {playerSubPosition ? "-" + playerSubPosition : ""}
              </h3>
            </div>
            <Button
              variant="outline"
              color="green"
              className="me-3"
              onClick={addpracticehelper}
            >
              Save
            </Button>
          </div>

          <div className="Stats d-flex align-items-start justify-content-between flex-wrap pt-5">
            {stats?.map((currstat, index) => (
              <div
                className="Editable_stats d-flex align-items-center justify-content-between px-2 mb-5"
                // onClick={() => setStage(7)}
              >
                <div className="d-flex align-items-center ">
                  <img src="images/barchart.png" alt="" className="me-3" />
                  <p>{currstat?.name}</p>
                </div>
                {!currstat?.edit && (
                  <div className="d-flex align-items-center">
                    <div className="progress_cont">
                      <RingProgress
                        size={40}
                        thickness={4}
                        sections={[
                          { value: currstat?.value * 10, color: "#009b3a" },
                        ]}
                      />
                    </div>

                    <p className=" number_stat mx-3">{currstat?.value}</p>
                    <img
                      src="images/write.png"
                      alt=""
                      onClick={() => {
                        const newstats = [...stats];
                        newstats[index].edit = true;
                        setstats(newstats);
                      }}
                    />
                  </div>
                )}
                {currstat?.edit && (
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="number_input_cont">
                      <NumberInput
                        value={currstat?.value}
                        placeholder="0"
                        max={10}
                        min={0}
                        clampBehavior="strict"
                        allowNegative={false}
                        allowDecimal={false}
                        radius="sm"
                        className="mx-3"
                        onChange={(e) => {
                          const newstats = [...stats];
                          newstats[index].value = e;
                          setstats(newstats);
                        }}
                      />
                    </div>
                    <Button
                      variant="outline"
                      color="green"
                      size="xs"
                      radius="lg"
                      onClick={() => {
                        const newstats = [...stats];
                        newstats[index].edit = false;
                        setstats(newstats);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      {stage == 4 && (
        <>
          <DataTable
            columns={columns}
            data={data}
            className="custom_table"
            subHeaderAlign={Center}
          />
        </>
      )}
      <Modal
        opened={confirmationModal}
        onClose={() => setconfirmationModal(false)}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="date_delete_modal"
      >
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <h5 className="mb-3">Are you sure you want to delete this player?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={() => setconfirmationModal(false)}
            >
              Decline
            </Button>
            <Button
              variant="filled"
              color="rgba(163, 2, 2, 1)"
              onClick={() => setdeletedateConfirmation(true)}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Practice;
