import React, { useEffect, useState } from "react";
import "./RegisteredTeamsStyles.scss";
import { Input, TextInput } from "@mantine/core";
import { Avatar, Modal } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { update } from "../auth/authslice";
import TeamSkelletonLoader from "./TeamSkelletonLoader";

import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { useDisclosure } from "@mantine/hooks";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mantine/core";
import axios from "axios";
import { toast } from "react-toastify";
const RegisteredTeams = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [teams, setTeams] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  const addteam = async () => {
    try {
      const response = await axios.post(`${API_URL}/addTeam`, {
        instituteId: user?._id,
        teamName: searchValue,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Team Successfully added</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 400) {
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Number of teams allowed exceeded</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    } finally {
      setSearchValue("");
      getTeams();
      const userdata = {
        type: "Institute",
        _id: user?._id,
      };
      dispatch(update(userdata));
      close();
    }
  };
  const [loading, setloading] = useState(false);
  const getTeams = async () => {
    setloading(true);
    try {
      const response = await axios.post(`${API_URL}/getAllTeamsForInstitute`, {
        instituteId: user?._id,
      });
      console.log(response.data);
      setTeams(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    getTeams();
  }, []);

  //remove team
  const [removeTeamMode, setremoveTeamMode] = useState(false);
  const [TeamtoRemove, setTeamtoRemove] = useState();
  const [modalopen, setmodalopen] = useState(false);
  const removeTeamHelper = (team) => {
    if (removeTeamMode) {
      setTeamtoRemove(team);
      setmodalopen(true);
    }
  };
  const removeTeam = async () => {
    try {
      setmodalopen(false);
      setremoveTeamMode(false);
      const response = await axios.post(`${API_URL}/RemoveTeam`, {
        InstituteId: user?._id,
        TeamName: TeamtoRemove,
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 400) {
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">You cannot delete Unassigned Team</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    } finally {
      getTeams();
    }
  };
  return (
    <div className="dashboard_content px-4 py-3">
      <div className="main_top d-flex justify-content-end align-items-center">
        <div className="main_top_right d-flex justify-content-between align-items-center ">
          <div className="notification_cont">
            <FontAwesomeIcon icon={faBell} size="xl" />
          </div>
          <Avatar color="cyan" radius="xl">
            {/* <img src="images/team-logo.png" alt="" /> */}
            <img className="user_image" src={user?.Image} alt="" />
          </Avatar>
        </div>
      </div>
      <div className="Registered_players_cont">
        <div className="register_top d-flex align-items-center justify-content-between mt-4 mb-3">
          <h5>Registered teams</h5>
          <div>
            <Button
              variant="outline"
              size="md"
              color="green"
              leftSection={<FontAwesomeIcon icon={faCirclePlus} />}
              onClick={open}
              className="me-3"
            >
              Add Teams
            </Button>
            <Button
              variant={removeTeamMode ? "filled" : "outline"}
              size="md"
              color="red"
              onClick={() => setremoveTeamMode(!removeTeamMode)}
            >
              Delete Teams
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start flex-wrap">
          {!loading &&
            teams.map((team, index) => (
              <div
                className={`Team_box m-2 d-flex flex-column align-items-center justify-content-center ${
                  removeTeamMode ? "delete_Team_box" : ""
                }`}
                onClick={() => removeTeamHelper(team)}
              >
                {/* <img src="images/team-logo.png" alt="" /> */}
                <img className="user_image" src={user?.Image} alt="" />
                <div className="design_box d-flex align-items-center justify-content-center">
                  <h4>{team}</h4>
                </div>
              </div>
            ))}
          {loading && <TeamSkelletonLoader />}
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          placeholder="Team Name"
          value={searchValue}
          radius="md"
          size="lg"
          label="Team Name"
          className="mt-5"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="d-flex align-items-center justify-content-center pt-4  mt-5">
          <Button variant="filled" color="green" onClick={addteam}>
            Add
          </Button>
        </div>
      </Modal>
      <Modal
        opened={modalopen}
        onClose={() => setmodalopen(false)}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="remove_player_modal"
      >
        <div className="d-flex flex-column align-items-center justify-content-between my-5">
          <h5 className="mb-3" style={{ textAlign: "center" }}>
            Are you sure you want to delete this player?
            <br />
            <span id="note">
              All players in this team will be moved to unassigned
            </span>
          </h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={() => setmodalopen(false)}
            >
              Decline
            </Button>
            <Button
              variant="filled"
              color="rgba(163, 2, 2, 1)"
              onClick={removeTeam}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RegisteredTeams;
