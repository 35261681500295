import React, { useState, useEffect } from "react";
import { Input } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { RingProgress, Text, Center } from "@mantine/core";
import { Progress } from "@mantine/core";
import { Accordion } from "@mantine/core";
import { Button } from "@mantine/core";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import "./UserDashboardStyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumberInput } from "@mantine/core";
import { toast } from "react-toastify";

import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { directUpdateUser } from "../auth/authslice";
import { Menu } from "@mantine/core";

const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/`;
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("user dashboard----------", user);
  }, [user]);

  useEffect(() => {
    if (user === null) {
      navigate("/chooseuser");
    } else {
      // getPlayer();
    }
  }, [user]);
  // const [requests, setrequests] = useState([]);
  // // const [Player, setPlayer] = useState({});
  // const getrequests = async () => {
  //   const response = await axios.post(
  //     `${port}/api/Request/viewIncomingRequests`,
  //     {
  //       id: user?._id,
  //     }
  //   );
  //   console.log(response.data);
  //   setrequests(response.data);
  // };

  // const approveRequest = async (request, status) => {
  //   try {
  //     const response = await axios.post(
  //       `${port}/api/Request/respondToRequest`,
  //       {
  //         requestId: request?._id,
  //         response: status,
  //       }
  //     );
  //     console.log(response.data);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     getrequests();
  //   }
  // };

  const [isWithinBreakpoint, setIsWithinBreakpoint] = useState(false);
  const checkBreakpoint = () => {
    const width = window.innerWidth;
    setIsWithinBreakpoint(width >= 1024 && width <= 1300);
  };

  useEffect(() => {
    // Check the breakpoint on initial mount
    checkBreakpoint();

    // Add event listener for window resize
    window.addEventListener("resize", checkBreakpoint);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);
  const ringprogressSize = isWithinBreakpoint ? 120 : 150;
  const ringprogressThickness = 15;
  const progressSize = "xl";
  const progressRadius = "lg";
  const getColor = (effort) => {
    if (effort < 50) {
      return "#EB2525";
    } else if (effort >= 50 && effort <= 80) {
      return "#FEDF00";
    } else {
      return "#028A37";
    }
  };
  const [loading, setloading] = useState(false);
  const [playerWeight, setplayerWeight] = useState();
  const [playerFeet, setplayerFeet] = useState();
  const [playerInches, setplayerInches] = useState();

  const updatePlayerInfo = async () => {
    setloading(true);
    let updates = {};

    if (playerWeight > 0) {
      updates["weight"] = playerWeight;
    }

    if (playerFeet > 0) {
      updates["feet"] = playerFeet;
    }

    if (playerInches > 0) {
      updates["inches"] = playerInches;
    }

    try {
      if (Object.keys(updates).length > 0) {
        const response = await axios.post(
          `${port}/api/users/Player/update-info`,
          { id: user?._id, updates: updates }
        );

        console.log(response?.data);

        dispatch(directUpdateUser(response.data));
        toast.success(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Player Updated Successfully</p>
          </>,

          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error Updating Player</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setloading(false);
      close();
    }
  };
  useEffect(() => {
    if (!opened) {
      setplayerFeet();
      setplayerInches();
      setplayerWeight();
    }
  }, [opened]);

  return (
    <>
      <div className="main_content pt-3">
        <div className="row">
          <div className="col-6 mb-3">
            <div className="image_cont d-flex flex-column align-items-center justify-content-end">
              {/* <img src="images/player-main.png" id="main_image" alt="" /> */}
              <img src={user?.Image} id="main_image" alt="" />

              <div className="d-flex flex-column align-items-center justify-content-center info_box p-3">
                <div className="d-flex align-items-center justify-content-between info_content mb-2">
                  <h1 className="player_name">
                    {user?.firstName + " " + user?.lastName}
                  </h1>
                  <FontAwesomeIcon
                    size="lg"
                    color="#009B3A"
                    icon={faPenToSquare}
                    style={{ cursor: "pointer" }}
                    onClick={() => open()}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-start info_content mb-2">
                  <h5 className="college">
                    {user?.InstituteName ? user?.InstituteName : "Not enrolled"}
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-start info_content mb-2">
                  <div className="d-flex flex-column align-items-start justify-content-start categry_cont ">
                    <p className="category">WEIGHT</p>
                    <p>{user?.Weight}lbs</p>
                  </div>
                  <div className="d-flex flex-column align-items-start justify-content-start categry_cont  ">
                    <p className="category">HEIGHT</p>
                    <p>{user?.Feet + "ft " + user?.Inches + '"'}</p>
                  </div>
                  <div className="d-flex flex-column align-items-start justify-content-start categry_cont ">
                    <p className="category">INSTITUTE</p>
                    <p>
                      {user?.InstituteName
                        ? user?.InstituteName
                        : "Not enrolled"}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center info_content mb-2">
                  <div className="design_3d d-flex align-items-center justify-content-center py-2 px-4">
                    <h4>
                      Player Success Probability :{" "}
                      <span
                        style={{
                          color: getColor(
                            user?.Averages?.OverallPercentage
                              ? user?.Averages?.OverallPercentage
                              : 0
                          ),
                        }}
                      >
                        {user?.Averages?.OverallPercentage
                          ? user?.Averages?.OverallPercentage
                          : 0}
                        %
                      </span>
                    </h4>
                  </div>
                  <div className="compliement_box d-flex">
                    <div className="section" id="section-1"></div>
                    <div className="section" id="section-2"></div>
                    <div className="section" id="section-3"></div>
                    <div className="section" id="section-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 mb-3 metrics_column ">
            <div className="technincal_metrics px-3 pt-3">
              <h5>Overall Technical Metrics</h5>
              <div className="techincal_metrics_data d-flex justify-content-center pb-2">
                <div className="data_cont d-flex flex-column align-items-center">
                  <RingProgress
                    thickness={ringprogressThickness}
                    size={ringprogressSize}
                    sections={[
                      {
                        value: user?.Averages?.attendancePercentage
                          ? user?.Averages?.attendancePercentage
                          : 0,
                        color: getColor(
                          user?.Averages?.attendancePercentage
                            ? user?.Averages?.attendancePercentage
                            : 0
                        ),
                      },
                    ]}
                    rootColor="rgba(255, 255, 255, 0.05)"
                    label={
                      <Text
                        c={getColor(
                          user?.Averages?.attendancePercentage
                            ? user?.Averages?.attendancePercentage + "%"
                            : 0
                        )}
                        fw={500}
                        ta="center"
                        size="lg"
                      >
                        {user?.Averages?.attendancePercentage
                          ? user?.Averages?.attendancePercentage + "%"
                          : 0 + "%"}
                      </Text>
                    }
                  />
                  <h6>Attendance</h6>
                </div>
                <div className="data_cont d-flex flex-column align-items-center">
                  <RingProgress
                    thickness={ringprogressThickness}
                    size={ringprogressSize}
                    sections={[
                      {
                        value: user?.Averages?.punctualPercentage
                          ? user?.Averages?.punctualPercentage
                          : 0,
                        color: getColor(
                          user?.Averages?.punctualPercentage
                            ? user?.Averages?.punctualPercentage
                            : 0
                        ),
                      },
                    ]}
                    rootColor="rgba(255, 255, 255, 0.05)"
                    label={
                      <Text
                        c={getColor(
                          user?.Averages?.punctualPercentage
                            ? user?.Averages?.punctualPercentage + "%"
                            : 0
                        )}
                        fw={500}
                        ta="center"
                        size="lg"
                      >
                        {user?.Averages?.punctualPercentage
                          ? user?.Averages?.punctualPercentage + "%"
                          : 0 + "%"}
                      </Text>
                    }
                  />
                  <h6>Punctual</h6>
                </div>
                <div className="data_cont d-flex flex-column align-items-center">
                  <RingProgress
                    thickness={ringprogressThickness}
                    size={ringprogressSize}
                    sections={[
                      {
                        value: user?.Averages?.extraTimePercentage
                          ? user?.Averages?.extraTimePercentage
                          : 0,
                        color: getColor(
                          user?.Averages?.extraTimePercentage
                            ? user?.Averages?.extraTimePercentage
                            : 0
                        ),
                      },
                    ]}
                    rootColor="rgba(255, 255, 255, 0.05)"
                    label={
                      <Text
                        c={getColor(
                          user?.Averages?.extraTimePercentage
                            ? user?.Averages?.extraTimePercentage
                            : 0
                        )}
                        fw={500}
                        ta="center"
                        size="lg"
                      >
                        {user?.Averages?.extraTimePercentage
                          ? user?.Averages?.extraTimePercentage + "%"
                          : 0 + "%"}
                      </Text>
                    }
                  />
                  <h6>Extra Time</h6>
                </div>
                <div className="data_cont d-flex flex-column align-items-center">
                  <RingProgress
                    thickness={ringprogressThickness}
                    size={ringprogressSize}
                    sections={[
                      {
                        value: user?.Averages?.filmPercentage
                          ? user?.Averages?.filmPercentage
                          : 0,
                        color: getColor(
                          user?.Averages?.filmPercentage
                            ? user?.Averages?.filmPercentage
                            : 0
                        ),
                      },
                    ]}
                    rootColor="rgba(255, 255, 255, 0.05)"
                    label={
                      <Text
                        c={getColor(
                          user?.Averages?.filmPercentage
                            ? user?.Averages?.filmPercentage
                            : 0
                        )}
                        fw={500}
                        ta="center"
                        size="lg"
                      >
                        {user?.Averages?.filmPercentage
                          ? user?.Averages?.filmPercentage + "%"
                          : 0 + "%"}
                      </Text>
                    }
                  />
                  <h6>Film</h6>
                </div>
              </div>
            </div>
            <div className="general_metrics mt-3 px-3 pt-3">
              <h5>Overall General Metrics</h5>
              <div className="general_metrics_data d-flex flex-column justify-content-between">
                <div className="data_cont mb-3 d-flex flex-column">
                  <div className="data d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-start mb-2">
                      <img src="images/Effort.png" alt="" className="me-2" />
                      <h6>Effort</h6>
                    </div>
                    <p className="quant">
                      <span
                        style={{
                          color: getColor(
                            (user?.Averages?.avgEffort
                              ? user?.Averages?.avgEffort / 5
                              : 0) * 100
                          ),
                        }}
                      >
                        {(user?.Averages?.avgEffort
                          ? user?.Averages?.avgEffort / 5
                          : 0) * 100}
                      </span>
                      /100
                    </p>
                  </div>
                  <Progress
                    size={progressSize}
                    radius={progressRadius}
                    value={
                      (user?.Averages?.avgEffort
                        ? user?.Averages?.avgEffort / 5
                        : 0) * 100
                    }
                    color={getColor(
                      (user?.Averages?.avgEffort
                        ? user?.Averages?.avgEffort / 5
                        : 0) * 100
                    )}
                  />
                </div>
                <div className="data_cont mb-3 d-flex flex-column">
                  <div className="data d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-start mb-2">
                      <img src="images/Coachable.png" alt="" className="me-2" />
                      <h6>Coachable</h6>
                    </div>

                    <p className="quant">
                      <span
                        style={{
                          color: getColor(
                            (user?.Averages?.avgCoachable
                              ? user?.Averages?.avgCoachable / 5
                              : 0) * 100
                          ),
                        }}
                      >
                        {(user?.Averages?.avgCoachable
                          ? user?.Averages?.avgCoachable / 5
                          : 0) * 100}
                      </span>
                      /100
                    </p>
                  </div>
                  <Progress
                    size={progressSize}
                    radius={progressRadius}
                    value={
                      (user?.Averages?.avgCoachable
                        ? user?.Averages?.avgCoachable / 5
                        : 0) * 100
                    }
                    color={getColor(
                      (user?.Averages?.avgCoachable
                        ? user?.Averages?.avgCoachable / 5
                        : 0) * 100
                    )}
                  />
                </div>
                <div className="data_cont mb-3 d-flex flex-column">
                  <div className="data d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-start mb-2">
                      <img
                        src="images/Body-Language.png"
                        alt=""
                        className="me-2"
                      />
                      <h6>Body Language</h6>
                    </div>

                    <p className="quant">
                      <span
                        style={{
                          color: getColor(
                            (user?.Averages?.avgBodyLanguage
                              ? user?.Averages?.avgBodyLanguage / 5
                              : 0) * 100
                          ),
                        }}
                      >
                        {(user?.Averages?.avgBodyLanguage
                          ? user?.Averages?.avgBodyLanguage / 5
                          : 0) * 100}
                      </span>
                      /100
                    </p>
                  </div>
                  <Progress
                    size={progressSize}
                    radius={progressRadius}
                    value={
                      (user?.Averages?.avgBodyLanguage
                        ? user?.Averages?.avgBodyLanguage / 5
                        : 0) * 100
                    }
                    color={getColor(
                      (user?.Averages?.avgBodyLanguage
                        ? user?.Averages?.avgBodyLanguage / 5
                        : 0) * 100
                    )}
                  />
                </div>
                <div className="data_cont mb-3 d-flex flex-column">
                  <div className="data d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-start mb-2">
                      <img
                        src="images/Mental-Toughness.png"
                        alt=""
                        className="me-2"
                      />
                      <h6>Mental Toughness</h6>
                    </div>

                    <p className="quant">
                      <span
                        style={{
                          color: getColor(
                            (user?.Averages?.avgMentalToughness
                              ? user?.Averages?.avgMentalToughness / 5
                              : 0) * 100
                          ),
                        }}
                      >
                        {(user?.Averages?.avgMentalToughness
                          ? user?.Averages?.avgMentalToughness / 5
                          : 0) * 100}
                      </span>
                      /100
                    </p>
                  </div>
                  <Progress
                    size={progressSize}
                    radius={progressRadius}
                    value={
                      (user?.Averages?.avgMentalToughness
                        ? user?.Averages?.avgMentalToughness / 5
                        : 0) * 100
                    }
                    color={getColor(
                      (user?.Averages?.avgMentalToughness
                        ? user?.Averages?.avgMentalToughness / 5
                        : 0) * 100
                    )}
                  />
                </div>
                <div className="data_cont mb-3 d-flex flex-column">
                  <div className="data d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-start mb-2">
                      <img
                        src="images/Physical-Toughness.png"
                        alt=""
                        className="me-2"
                      />
                      <h6>Physical Toughness</h6>
                    </div>

                    <p className="quant">
                      <span
                        style={{
                          color: getColor(
                            (user?.Averages?.avgPhysicalToughness
                              ? user?.Averages?.avgPhysicalToughness / 5
                              : 0) * 100
                          ),
                        }}
                      >
                        {(user?.Averages?.avgPhysicalToughness
                          ? user?.Averages?.avgPhysicalToughness / 5
                          : 0) * 100}
                      </span>
                      /100
                    </p>
                  </div>
                  <Progress
                    size={progressSize}
                    radius={progressRadius}
                    value={
                      (user?.Averages?.avgPhysicalToughness
                        ? user?.Averages?.avgPhysicalToughness / 5
                        : 0) * 100
                    }
                    color={getColor(
                      (user?.Averages?.avgPhysicalToughness
                        ? user?.Averages?.avgPhysicalToughness / 5
                        : 0) * 100
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size={"md"}
        id="player_update_modal"
      >
        <div className="d-flex flex-column align-items-center update_player_cont ">
          <h4 className="player_update_header">Update Player Info</h4>
          <div className="input_cont mt-3">
            <Input.Wrapper label={<p className="mb-2 input_label">Weight</p>}>
              <NumberInput
                placeholder="Enter Weight in lbs"
                radius={"md"}
                size={"lg"}
                allowDecimal={false}
                allowNegative={false}
                allowLeadingZeros={false}
                clampBehavior="strict"
                value={playerWeight}
                onChange={setplayerWeight}
              />
            </Input.Wrapper>
          </div>
          <div className=" input_cont d-flex align-items-center justify-content-between mt-3 mb-4 ">
            <Input.Wrapper
              label={<p className="mb-2 input_label">Feet</p>}
              className="me-2"
            >
              <NumberInput
                placeholder="Feet"
                radius={"md"}
                size={"lg"}
                allowDecimal={false}
                allowNegative={false}
                allowLeadingZeros={false}
                clampBehavior="strict"
                value={playerFeet}
                onChange={setplayerFeet}
              />
            </Input.Wrapper>
            <Input.Wrapper
              label={<p className="mb-2 input_label">Inches</p>}
              className="ms-2"
            >
              <NumberInput
                placeholder="Inches"
                radius={"md"}
                size={"lg"}
                allowDecimal={false}
                allowNegative={false}
                allowLeadingZeros={false}
                clampBehavior="strict"
                value={playerInches}
                onChange={setplayerInches}
              />
            </Input.Wrapper>
          </div>
          <Button
            variant="filled"
            color="#009B3A"
            loading={loading}
            onClick={() => updatePlayerInfo()}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UserDashboard;
