import React, { useEffect } from "react";
import "../styles/CreateAccStyles.css";
import { Button } from "@mantine/core";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const port = process.env.REACT_APP_IP_ADDRESS;
const API_URL = `${port}/api/users/`;

const CreateAcc = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Token = queryParams.get("Token");
  const type = queryParams.get("type");

  useEffect(() => {
    if (Token && type) {
      // Construct API URL based on type
      let apiUrl = "";
      if (type === "Coach") {
        apiUrl = `${API_URL}Coach/verify-token`;
      } else if (type === "Player") {
        apiUrl = `${API_URL}Player/verify-token`;
      } else if (type === "Scout") {
        apiUrl = `${API_URL}Scout/verify-token`;
      }

      // Call API
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: Token }), // Send Token as 'code' in the request body
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle API response
          console.log(data);
          // Display success toast
          toast.success(
            <>
              <img
                src="/images/new-logo.png"
                className="toast_logo"
                alt="Logo"
              />
              <p className="mt-3">Account verified</p>
            </>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        })
        .catch((error) => {
          // Handle API error
          console.error("Error:", error);
          // Display error toast
          toast.error(
            <>
              <img
                src="/images/new-logo.png"
                className="toast_logo"
                alt="Logo"
              />
              <p className="mt-3">Accunt verification failed</p>
            </>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        });
    }
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  return (
    <div className="createAcc_confirmation_cont d-flex flex-column align-items-center justify-content-center">
      <img src="/images/new-logo.png" alt="" id="logo" />
      <img
        src="/images/thankyou_inst.png"
        alt=""
        className="CreateAcc_gray_scale"
      />
      <h4 className="mb-5">
        Your details have been verified and your account has been created
        <br />
        Thank you for choosing Athlantix{" "}
      </h4>
      <Button
        variant="outline"
        radius="md"
        className="mb-2"
        color="green"
        onClick={() => navigate("/chooseuser")}
      >
        Go back home
      </Button>
    </div>
  );
};

export default CreateAcc;
