import React, { useEffect, useState } from "react";
import "./RegisteredPlayerStyles.scss";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { Input, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoachSkelletonLoader from "./CoachSkelletonLoader";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
  faArrowLeft,
  faPerson,
  faGlobe,
  faUser,
  faEnvelope,
  faCalendarDays,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { Button } from "@mantine/core";

import axios from "axios";
import SearchCoach from "./SearchCoach";

const RegisteredCoaches = ({ hideSidebar = () => {} }) => {
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);
  const [addCoachModal, { open: openAddCoach, close: closeAddCoach }] =
    useDisclosure(false);

  const [newCoachEmail, setNewCoachEmail] = useState("");
  const [emailSending, setEmailSending] = useState(false); // State to manage the loading state of the email button

  const [successModal, { open: openSuccessModal, close: closeSuccessModal }] =
    useDisclosure(false);

  useEffect(() => {
    getCoaches();
  }, []);
  const getCoaches = async () => {
    // setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/getAllCoachesFromInstitute`,
        {
          id: user._id,
        }
      );
      console.log(response.data);
      setCoaches(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const [search, setSearch] = useState(false);

  useEffect(() => {
    if (!search) {
      setDeleteCoach(false);
    }
    if (search) {
      getCoaches();
    }
  }, [search]);

  useEffect(() => {
    if (coaches?.length > 0) {
      setSearch(true);
    }
  }, [coaches]);
  const goback = () => {
    setSearch(true);
  };
  useEffect(() => {
    console.log(loading);
  }, [loading]);
  //delete coaches
  const [deleteCoach, setDeleteCoach] = useState(false);
  const [confirmationModal, setconfirmationModal] = useState(false);
  const [deleteCoachConfirmation, setdeleteCoachConfirmation] = useState(false);
  const [coachtobedeleted, setcoachtobedeleted] = useState();

  const deletecoachhelper = (coach) => {
    if (deleteCoach) {
      setcoachtobedeleted(coach);
      setconfirmationModal(deleteCoach);
    }
  };
  useEffect(() => {
    if (deleteCoachConfirmation) {
      removeCoach();
    }
  }, [deleteCoachConfirmation]);
  const removeCoach = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/RemoveCoach`,
        {
          instituteId: user._id,
          coachId: coachtobedeleted._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      getCoaches();
      setconfirmationModal(false);
      setdeleteCoachConfirmation(false);
      setDeleteCoach(false);
      setcoachtobedeleted();
    }
  };

  const [isSidebarHidden, setisSidebarHidden] = useState(false);
  const helpsidebarhide = () => {
    hideSidebar();
    setisSidebarHidden(!isSidebarHidden);
  };

  const [CoachDetailsModal, setCoachDetailsModal] = useState(false);
  const [CoachDetails, setCoachDetails] = useState();

  const resetForm = () => {
    setNewCoachEmail("");
  };

  const addCoach = async () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(newCoachEmail)) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" alt="Logo" />
          <p className="mt-3">Please enter a valid email address</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return; // Stop execution if the email is invalid
    }
    setEmailSending(true); // Start loading before the request

    try {
      // First, check if the user exists
      const response = await axios.post(
        `${API_URL}/checkifuserexists`,
        { email: newCoachEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle response based on existence of user
      if (response.data.exists) {
        showErrorToast(response);
        setEmailSending(false);

        return;
      }

      const createResponse = await axios.post(
        `${API_URL}/createSignUpLinkCoach`,
        {
          email: newCoachEmail,
          instituteId: user._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      getCoaches();
      setEmailSending(false);
      closeAddCoach();
      resetForm();
      openSuccessModal(); // Show the success modal after a successful request
    } catch (err) {
      console.error(err);
      setEmailSending(false);
      handleCloseAddCoach(); // Close the modal and reset the form on error
      showErrorToast(
        err.response || "An error occurred while sending the invitation."
      ); // Show error based on the response
    }
  };

  const handleCloseAddCoach = () => {
    closeAddCoach();
    resetForm();
  };

  const showErrorToast = (response) => {
    const message =
      response && response.data && response.data.message
        ? response.data.message
        : "An unexpected error occurred"; // Fallback message if no specific message found

    toast.error(
      <>
        <img src="images/new-logo.png" className="toast_logo" alt="Logo" />
        <p className="mt-3">{message}</p>
      </>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };

  return (
    <div
      className={`dashboard_content px-4 py-3 ${
        isSidebarHidden && "no_sidebar_cont"
      }`}
    >
      <div className="main_top d-flex justify-content-end align-items-center">
        {/* <div className="input_cont">
          <Input
            size="md"
            radius="xl"
            placeholder="Search"
            leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          />
        </div> */}
        <div className="main_top_right d-flex justify-content-between align-items-center ">
          <div className="notification_cont">
            <FontAwesomeIcon icon={faBell} size="xl" />
          </div>
          <Avatar color="cyan" radius="xl">
            {/* <img src="images/team-logo.png" alt="" /> */}
            <img className="user_image" src={user?.Image} alt="" />
          </Avatar>
        </div>
      </div>
      <div className="Registered_players_cont">
        <div className="register_top d-flex align-items-center justify-content-between mt-4 mb-3">
          {!loading && !search && !isSidebarHidden && (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_icon"
                onClick={goback}
              />

              <h5>Search Coaches</h5>
            </div>
          )}
          {!loading && search && <h5>Search Coaches</h5>}
          {!loading && search && (
            <div>
              <Button
                variant="outline"
                size="md"
                color="green"
                leftSection={<FontAwesomeIcon icon={faCirclePlus} />}
                // onClick={() => setSearch(false)}
                onClick={openAddCoach}
                className="me-3"
              >
                Add Coaches
              </Button>
              {coaches.length > 0 && (
                <Button
                  variant={deleteCoach ? "filled" : "outline"}
                  size="md"
                  color="red"
                  onClick={() => setDeleteCoach(!deleteCoach)}
                >
                  Delete Coaches
                </Button>
              )}
            </div>
          )}
        </div>
        {!loading && search === false && (
          <SearchCoach hideSidebar={() => helpsidebarhide()} />
        )}

        {!loading && search && (
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            {coaches.length === 0 ? (
              <div className="empty-coaches-container">
                <img src="images/empty-coach.png" alt="No coaches available" />
              </div>
            ) : (
              coaches?.map((coach) => (
                <div className="m-2">
                  <div
                    className={`Player ${deleteCoach ? "delete_coach" : ""}`}
                    onClick={() => deletecoachhelper(coach)}
                  >
                    <div className="row">
                      <div className="col-6">
                        <img src={coach?.Image} alt="" />
                      </div>
                      <div className="col-6 pt-4">
                        <h5>{coach?.firstName + " " + coach?.lastName}</h5>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="me-2"
                            color="#009B3A"
                          />
                          <p>Registered</p>
                        </div>
                        <div className="d-flex align-items-center mt-2 "></div>
                        <div className="d-flex align-items-center mt-2 "></div>
                        <div className="button_cont d-flex align-items-center mt-2">
                          <Button
                            variant="filled"
                            color="rgba(57, 60, 82, 0.2)"
                            size="xs"
                            radius="xl"
                            className="me-3"
                            onClick={() => {
                              setCoachDetails(coach);
                              open();
                            }}
                          >
                            Check Profile
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
        {loading && <CoachSkelletonLoader />}
      </div>

      <Modal
        opened={confirmationModal}
        onClose={() => setconfirmationModal(false)}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="date_delete_modal"
      >
        <div className="d-flex flex-column align-items-center justify-content-center my-5">
          <h5 className="mb-3">Are you sure you want to delete this Coach?</h5>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="filled"
              className="me-2"
              color="rgba(5, 128, 23, 1)"
              onClick={() => setconfirmationModal(false)}
            >
              Decline
            </Button>
            <Button
              variant="filled"
              color="rgba(163, 2, 2, 1)"
              onClick={() => setdeleteCoachConfirmation(true)}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered={true}
        size="xl"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        id="CoachDetailsModal"
      >
        <div className="CoachDetails_cont d-flex align-items-start jsutify-content-center pt-5 px-5">
          <div className="d-flex flex-column align-items-start justiy-content-evenly">
            <div className="image_cont">
              <img src={CoachDetails?.Image} alt="" />
            </div>
            <div className="mt-5 d-flex ">
              <div>
                <FontAwesomeIcon size="lg" color="#009b3a" icon={faPerson} />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <p className="Category ms-3">Age</p>
                <p className="Value ms-3">{CoachDetails?.age}</p>
              </div>
            </div>
            <div className="mt-5 d-flex ">
              <div>
                <FontAwesomeIcon size="lg" color="#009b3a" icon={faGlobe} />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <p className="Category ms-3">State</p>
                <p className="Value ms-3">{CoachDetails?.state}</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-between ms-5 ps-3">
            <div className=" mt-3 d-flex ">
              <div>
                <FontAwesomeIcon size="lg" color="#009b3a" icon={faUser} />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <p className="Category ms-3">Name</p>
                <p className="Value ms-3">
                  {CoachDetails?.firstName + " " + CoachDetails?.lastName}
                </p>
              </div>
            </div>
            <div className="mt-5 d-flex ">
              <div>
                <FontAwesomeIcon size="lg" color="#009b3a" icon={faEnvelope} />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <p className="Category ms-3">Email</p>
                <p className="Value ms-3">{CoachDetails?.email}</p>
              </div>
            </div>
            <div className="mt-5 d-flex ">
              <div>
                <FontAwesomeIcon
                  size="lg"
                  color="#009b3a"
                  icon={faCalendarDays}
                />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <p className="Category ms-3">Date of Birth</p>
                <p className="Value ms-3">{CoachDetails?.age}</p>
              </div>
            </div>
            <div className="mt-5 d-flex ">
              <div>
                <FontAwesomeIcon
                  size="lg"
                  color="#009b3a"
                  icon={faLocationDot}
                />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <p className="Category ms-3">Address</p>
                <p className="Value ms-3">{CoachDetails?.address}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* two new models */}

      <Modal
        opened={addCoachModal}
        onClose={handleCloseAddCoach}
        withCloseButton={false}
        centered={true}
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="add_coach_modal_cont p-4">
          <div className="d-flex justify-content-center">
            {" "}
            <h2 style={{ marginBottom: "3vw" }}>Send Invitation to Coach</h2>
          </div>

          <div style={{ marginBottom: "3vw" }}>
            <Input
              size="lg"
              radius="xl"
              placeholder="Enter Coach Email"
              value={newCoachEmail}
              onChange={(e) => setNewCoachEmail(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4">
            <Button
              variant="outline"
              color="gray"
              className="me-2"
              onClick={handleCloseAddCoach}
              size="lg"
              radius="lg"
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              color="green"
              onClick={addCoach}
              size="lg"
              radius="lg"
              loading={emailSending}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        opened={successModal}
        onClose={closeSuccessModal}
        withCloseButton={false}
        centered
        size="lg"
        radius={10}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="success_modal_cont d-flex flex-column align-items-center justify-content-center my-5">
          <img
            src="images/invitation-success.png"
            alt="Success"
            className="success-image "
          />
          <h3 className="mb-5">Your invitation is successfully sent.</h3>
          <Button
            color="black"
            size="xl"
            radius="lg"
            onClick={closeSuccessModal}
            variant="white"
          >
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RegisteredCoaches;
