import React, { useState, useEffect, useRef, useMemo } from "react";
import "../styles/VideoEditingStyles.scss";
import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
import {
  faArrowLeft,
  faPlay,
  faPause,
  faSort,
  faAnglesUp,
  faAnglesDown,
  faBell,
  faArrowUpFromBracket,
  faEllipsisVertical,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { Slider } from "@mantine/core";
import { Avatar } from "@mantine/core";
import { useNavigate } from "react-router-dom";
//upload video
import { Group, Text, rem, Button } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, DropzoneProps, MIME_TYPES } from "@mantine/dropzone";
import "@mantine/dropzone/styles.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
const StudentVideoEditing = () => {
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentVideoData, setCurrentVideoData] = useState({});
  const [videoClips, setVideoClips] = useState([]);
  const videoID = "65c7a97af0b7036efe0f68c0";
  // const videoarray = [
  //   {
  //     url: videoID,
  //   },
  //   {
  //     url: videoID,
  //   },
  //   {
  //     url: videoID,
  //   },
  //   {
  //     url: videoID,
  //   },
  // ];
  const location = useLocation();
  const [video, setVideo] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  // useEffect(() => {
  //   if (upload) {
  //     open();
  //   }
  // }, [upload]);
  // useEffect(() => {
  //   if (!opened) {
  //     setupload(false);
  //   }
  // }, [opened]);

  const [uploadVideosrc, setuploadVideosrc] = useState(null);
  // function createObjectURL(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () =>
  //       resolve(URL.createObjectURL(new Blob([reader.result])));
  //     reader.onerror = reject;
  //     reader.readAsArrayBuffer(file);
  //   });
  // }
  // const uploadvideo = async (files) => {
  //   const file = files[0];
  //   const url = await createObjectURL(file);
  //   setuploadVideosrc(url);
  //   open();
  // };

  //video player customq controls
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const videoRef = useRef(null);
  const preloadVideoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [upload, setupload] = useState(false);
  const [goBack, setgoback] = useState(false);
  const [sliderThumbSize, setSliderThumbSize] = useState(14);
  const [trigger, setTrigger] = useState(false);

  const [SliderCurrentTime, setSliderCurrentTime] = useState(0);
  const [SliderMaxTime, setSliderMaxTime] = useState(0);
  const [SliderMinTime, setSliderMinTime] = useState(0);
  const [videoDurations, setVideoDurations] = useState([]);

  const sliderCurrentTimeRef = useRef(SliderCurrentTime);
  const currentTimeRef = useRef(currentTime);

  // Ensure refs are updated whenever the corresponding state changes
  useEffect(() => {
    sliderCurrentTimeRef.current = SliderCurrentTime;
  }, [SliderCurrentTime]);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  //play pause video
  // const playVideo = () => {
  //   if (video && videoRef.current.paused) {
  //     videoRef.current.play();
  //     setPlaying(true);
  //     setSliderThumbSize(0);
  //   } else {
  //     videoRef.current.pause();
  //     setPlaying(false);
  //     setSliderThumbSize(14);
  //   }
  // };
  const playVideo = () => {
    const video = videoRef.current;
    if (!video) return;

    const tryPlay = () => {
      video
        .play()
        .catch((error) =>
          console.error("Error trying to play the video:", error)
        );
    };

    if (video.paused) {
      if (video.readyState >= 3) {
        tryPlay();
      } else {
        const onCanPlay = () => {
          tryPlay();
          video?.removeEventListener("canplay", onCanPlay);
        };
        video.addEventListener("canplay", onCanPlay);
      }
      setPlaying(true);
      setSliderThumbSize(0);
    } else {
      video.pause();
      setPlaying(false);
      setSliderThumbSize(14);
    }
  };

  //current time of video

  //seek video

  const handleSeek = (value) => {
    setSliderCurrentTime(value);
    // sliderCurrentTimeRef.current = value;
    console.log("handle seek", value);
    console.log("vide durations", videoDurations);
    if (video?.length > 1) {
      // if (value > videoDurations[currentVideoIndex]) {
      if (value > currentVideoData?.endTime) {
        console.log("seeking to next video");

        for (let i = currentVideoIndex; i < video.length; i++) {
          if (value <= video[i]?.endTime && value >= video[i]?.startTime) {
            setCurrentVideoData(video[i]);
            setCurrentVideoIndex(i);
            let temp = currentVideoData?.endTime - value;
            setCurrentTime(video[i]?.startTime + temp);
            // currentTimeRef.current = video[i]?.startTime + temp;
            setTrigger(!trigger);
            break;
          }
        }
      }
      // else if (value < videoDurations[currentVideoIndex]) {
      else if (value < currentVideoData?.startTime) {
        for (let i = currentVideoIndex; i >= 0; i--) {
          if (value <= video[i]?.endTime && value >= video[i]?.startTime) {
            setCurrentVideoData(video[i]);
            setCurrentVideoIndex(i);

            setCurrentTime(value + video[i]?.startTime);
            // currentTimeRef.current = value + video[i]?.startTime;
            setTrigger(!trigger);
            break;
          }
        }
      } else {
        setCurrentTime(value);
        // currentTimeRef.current = value;
        const video = videoRef.current;
        video.currentTime = value;
      }
    } else {
      setCurrentTime(value);
      videoRef.current.currentTime = value;
    }
    console.log("current time", currentTime);
    console.log("slider current time", SliderCurrentTime);
  };
  const [showIcon, setShowIcon] = useState(false);
  //play pause icon
  useEffect(() => {
    setShowIcon(true);
    const timeoutId = setTimeout(() => setShowIcon(false), 2000); // Hide icon after 2 seconds
    return () => clearTimeout(timeoutId); // Clear timeout if `playing` changes before the timeout finishes
  }, [playing]);

  //go back
  // useEffect(() => {
  //   if (goBack) {
  //     // setTeam(null);
  //     setVideo(null);
  //     setupload(false);
  //     setgoback(false);
  //     setCurrentTime(0);
  //     setPlaying(false);
  //     setSliderThumbSize(14);
  //     // hideSidebar();
  //   }
  // }, [goBack]);

  //choose video

  useEffect(() => {
    ChooseVideo(location.state.video);
    console.log("location", location.state.video);
  }, [location.state.video]);
  const [IsEditable, setIsEditable] = useState(true);
  const [Ischanged, setIsChanged] = useState(false);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();

  const defaultColDef = useMemo(
    () => ({
      editable: false,
      flex: 1,
      minWidth: 100,
    }),
    []
  );
  // const defaultColDef = useMemo(
  //   () => ({
  //     editable: true,
  //     flex: 1,
  //     minWidth: 100,
  //   }),
  //   []
  // );
  const ChooseVideo = async (video) => {
    try {
      const res = await axios.get(`${API_URL}Videos/video/${video?._id}/clips`);
      setVideoClips(res.data);
      console.log("choose Video", res.data);
      console.log(res.data.Clips[0][0]);
      setVideo(res.data.Clips[videoIndex]);
      setIsEditable(
        !(
          res?.data?.isModifying ||
          res?.data?.markforAICuts ||
          res?.data?.markforVACuts
        )
      );

      setCurrentVideoData(res.data.Clips[0][0]);
      setcolumnDefs(res.data?.Header);
      res.data?.Clips?.map((video) => {
        video?.map((clip) => {
          setRowData((prev) => [...prev, clip?.ClipStats]);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log("rowData", rowData);
    console.log("columnDefs", columnDefs);
  }, [rowData]);
  //spliting video into 2
  //   const splitVideo = () => {
  //     // console.log("split");
  //     // console.log("video Index", videoIndex);
  //     // console.log("current Time", currentTime);
  //     if (IsEditable) {
  //       console.log("Current video Data", currentVideoData);
  //       console.log("All video Clips", videoClips);
  //       setIsChanged(true);
  //       const newClip = { ...currentVideoData };
  //       newClip.isChanged = true;
  //       newClip.isMainFile = false;
  //       newClip.endTime = currentTime;
  //       newClip.startTime = 0;
  //       newClip.duration = currentTime;
  //       currentVideoData.startTime = currentTime;
  //       currentVideoData.isChanged = true;
  //       currentVideoData.duration = currentVideoData.duration - currentTime;

  //       if (video?.length > 1) {
  //         video.splice(currentVideoIndex, 1, currentVideoData);
  //         videoClips.Clips.splice(videoIndex, 1, video);
  //       } else {
  //         videoClips.Clips.splice(videoIndex, 1, [currentVideoData]);
  //       }

  //       videoClips.Clips.splice(videoIndex, 0, [newClip]);
  //       setVideoClips(videoClips);
  //       console.log("Split video Clips", videoClips);
  //       setTrigger(!trigger);
  //     } else {
  //       toast.warning(
  //         <>
  //           <img src="images/new-logo.png" className="toast_logo" />
  //           <p className="mt-3">Cannot Edit Video</p>
  //         </>,
  //         {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         }
  //       );
  //     }
  //   };
  //sending obj to backend
  //   const SaveVideo = async () => {
  //     const allRowData = [];
  //     // gridRef.current.api.forEachNode((node) => allRowData.push(node.data));
  //     // console.log("allRowData", allRowData);
  //     try {
  //       const res = await axios.post(
  //         `${API_URL}Videos/process-clips`,
  //         videoClips
  //       );
  //       console.log(res);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   const SaveTable = async (clipid, data) => {
  //     try {
  //       const res = axios.post(`${API_URL}Videos/updateStats`, {
  //         clipId: clipid,
  //         Stats: data,
  //       });
  //       toast.success(
  //         <>
  //           <img src="images/new-logo.png" className="toast_logo" />
  //           <p className="mt-3">Table Saved Successfully</p>
  //         </>,
  //         {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         }
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   const SaveTableHelper = () => {
  //     gridRef.current.api.forEachNode((node, index) =>
  //       SaveTable(videoClips?.Clips[index][0]?._id, node.data)
  //     );
  //   };
  // combine two videos upp or down
  //   const combineVideo = (direction) => {
  //     if (IsEditable) {
  //       // console.log("combine");
  //       setIsChanged(true);
  //       let nextindex = 0;
  //       if (videoClips.Clips.length > 1) {
  //         if (direction === "up") {
  //           if (videoIndex > 0) {
  //             console.log("video Index", videoIndex, direction);
  //             nextindex = videoIndex - 1;
  //           } else {
  //             return; // return from the function if videoIndex is not greater than 0
  //           }
  //         }
  //         if (direction === "down") {
  //           if (videoIndex < videoClips.Clips.length - 1) {
  //             console.log("video Index", videoIndex, direction);
  //             nextindex = videoIndex + 1;
  //           } else {
  //             return; // return from the function if videoIndex is not less than the length of Clips array minus 1
  //           }
  //         }

  //         videoClips.Clips[nextindex][0].isChanged = true;
  //         if (direction === "up") {
  //           videoClips.Clips[nextindex]?.map((video) => {
  //             video.isChanged = true;
  //             videoClips.Clips[videoIndex].unshift(video);
  //           });
  //         }

  //         if (direction === "down") {
  //           videoClips.Clips[nextindex]?.map((video) => {
  //             video.isChanged = true;
  //             videoClips.Clips[videoIndex].push(video);
  //           });
  //         }
  //         videoClips.Clips.splice(nextindex, 1);
  //         setVideo(videoClips.Clips[videoIndex]);
  //         setCurrentVideoIndex(0);
  //         // videoRef.current.load();
  //         setSliderCurrentTime(videoClips.Clips[videoIndex][0]?.startTime);
  //         setTrigger(!trigger);
  //         console.log("Combined video", videoClips);
  //       } else {
  //         return; // return from the function if the length of Clips array is not greater than 1
  //       }
  //     } else {
  //       toast.warning(
  //         <>
  //           <img src="images/new-logo.png" className="toast_logo" />
  //           <p className="mt-3">Cannot Edit Video</p>
  //         </>,
  //         {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         }
  //       );
  //     }
  //   };

  //stitch video play
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  useEffect(() => {
    console.log("current video index newuseEffect", currentVideoIndex);
    console.log("video.length  newuseEffect", video?.length);
    if (video?.length > 1 && currentVideoIndex < video?.length - 1) {
      preloadVideoRef.current.src = `${API_URL}Videos/get-clip?clipId=${
        video[currentVideoIndex + 1]._id
      }`;
      preloadVideoRef.current.load();
    }
  }, [currentVideoIndex, video, , trigger]); // todo: no need

  //change video source
  useEffect(() => {
    if (video?.length > 0) {
      console.log("change video source");
      if (currentVideoIndex == 0) {
        setCurrentVideoData(video[currentVideoIndex]);
        console.log("cvideo[currentVideoIndex", video[currentVideoIndex]);
        videoRef.current.src = `${API_URL}Videos/get-clip?clipId=${video[currentVideoIndex]._id}`;
        console.log(" videoRef.current.src", videoRef.current.src);

        console.log("current video index", currentVideoIndex);
        videoRef.current.load(); // This is  crucial to reload the video element with the new source
        videoRef.current.currentTime = video[currentVideoIndex]?.startTime;
        videoRef.current
          ?.play()
          .catch((error) => console.error("Video play error:", error)); // Auto-play the video, handle potential errors
        setPlaying(true);
      } else {
        setCurrentVideoData(video[currentVideoIndex]);
        videoRef.current.src = preloadVideoRef.current.src;
        console.log("current video index", currentVideoIndex);
        // This is crucial to reload the video element with the new source
        // videoRef.current.load();
        setSliderCurrentTime(video[currentVideoIndex]?.startTime);
        videoRef.current.currentTime = currentTime;
        videoRef.current
          .play()
          .catch((error) => console.error("Video play error:", error)); // Auto-play the video, handle potential errors
        setPlaying(true);
      }
    }
  }, [video, currentVideoIndex, trigger]); // todo: no need

  useEffect(() => {
    console.log(currentVideoData);
  }, [currentVideoData]);

  // max size of whole video
  useEffect(() => {
    if (video) {
      console.log("update slider max time");
      // setSliderMaxTime(0);
      let max = 0;
      setVideoDurations([]);
      video?.map((video) => {
        max += video.endTime;
        setVideoDurations((prev) => {
          // Assuming you want to add to the last element of the array
          // and that the array contains integer values or strings that can be parsed as integers.
          const lastValue =
            prev.length > 0 ? parseInt(prev[prev.length - 1], 10) : 0;
          const currentDuration = parseInt(video.endTime, 10);

          // Return a new array with the added value
          return [...prev, lastValue + currentDuration];
        });
      });
      setSliderMaxTime(video[video?.length - 1]?.endTime);
      setSliderMinTime(video[0]?.startTime);
    }
  }, [video, trigger]); // todo: no need

  useEffect(() => {
    console.log(videoDurations);
  }, [videoDurations]);

  useEffect(() => {
    // console.log("slider current time", SliderCurrentTime);
    // console.log("slider max time", SliderMaxTime);
    // console.log("current video max time", currentVideoData?.endTime);
    // console.log("current video min time", currentVideoData?.startTime);
    // console.log("Slider.,min time", SliderMinTime);
  }, [SliderCurrentTime]); // todo: no need

  //update time and handle video end
  useEffect(() => {
    console.log("current video index", currentVideoIndex);
  }, [currentVideoIndex]); // todo: no need

  useEffect(() => {
    if (
      videoRef.current &&
      currentVideoData &&
      isFinite(currentVideoData.startTime)
    ) {
      // Set the start time
      videoRef.current.currentTime = currentVideoData?.startTime;

      const timeUpdateHandler = () => {
        if (!videoRef.current) return;
        const currentSliderTime = sliderCurrentTimeRef.current;
        const currentVideoTime = currentTimeRef.current;
        if (videoRef.current.currentTime >= currentVideoData?.endTime) {
          console.log("Time Ended");
          handleEnded();
        } else {
          setSliderCurrentTime(
            currentSliderTime + videoRef.current.currentTime - currentVideoTime
          );
        }
        setCurrentTime(videoRef.current.currentTime);
      };
      const handleEnded = () => {
        const currentVideoTime = currentTimeRef.current;
        console.log("handle ended");
        if (video?.length === 1) {
          console.log("ended length 1");
          if (videoRef.current) {
            videoRef.current.currentTime = currentVideoData?.startTime;
            videoRef.current.play();
          }
          setCurrentTime(0);
          setSliderCurrentTime(currentVideoData?.startTime - currentVideoTime); //here
        }

        if (currentVideoIndex + 1 < video.length && video?.length > 1) {
          setCurrentVideoData(video[currentVideoIndex + 1]);
          setCurrentVideoIndex(currentVideoIndex + 1);
          setCurrentTime(0);
        } else {
          setCurrentVideoIndex(0);
          setCurrentTime(0);
          setSliderCurrentTime(video[0]?.startTime);
        }
      };

      videoRef.current.addEventListener("timeupdate", timeUpdateHandler);
      videoRef.current.addEventListener("ended", handleEnded);

      return () => {
        videoRef.current?.removeEventListener("timeupdate", timeUpdateHandler);
        videoRef.current?.removeEventListener("ended", handleEnded);
      };
    }
  }, [videoRef.current, currentVideoData]); // todo: no need

  const navigate = useNavigate();

  //table logic below

  return (
    <div className="video_Editor_cont">
      {/* <>
        <Button variant="filled" color="gray" onClick={() => setupload(true)}>
          {" "}
          Upload
        </Button>
        <Button variant="filled" color="gray" onClick={() => setgoback(true)}>
          Back
        </Button>
        <Button variant="filled" color="gray" onClick={() => SaveVideo()}>
          Save
        </Button>
      </> */}
      <div className="video_editing_top d-flex align-items-center justify-content-between p-3">
        <div className="d-flex">
          <img src="images/new-logo.png" alt="" className="me-3" />
          <Button
            variant="outline"
            color="grey"
            radius={"xl"}
            leftSection={
              <FontAwesomeIcon
                icon={faArrowLeft}
                color="rgba(255,255,255,0.5)"
              />
            }
            onClick={() => navigate(-1)}
          >
            back
          </Button>
        </div>

        <div className="d-flex">
          {/* <Button
            variant="outline"
            color="green"
            radius={"xl"}
            leftSection={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
            className="me-3"
            onClick={() => SaveVideo()}
          >
            Save
          </Button> */}
          <FontAwesomeIcon icon={faBell} size="xl" className="me-3 mt-2" />
          <Avatar color="cyan" radius="xl">
            MK
          </Avatar>
        </div>
      </div>

      <>
        <div className="d-flex video_player_cont">
          <div className="video_player">
            <>
              <video
                width="100%"
                ref={videoRef}
                preload="auto"
                onClick={playVideo}
                key={video}
              >
                Your browser does not support the video tag.
              </video>
              <video
                width="100%"
                ref={preloadVideoRef}
                preload="auto"
                // key={video}
                id="preloadVideo"
              ></video>
            </>

            {showIcon && (
              <div className="video_status_cirlce d-flex align-items-center justify-content-center">
                {!playing ? (
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="video_status_icon"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPause}
                    className="video_status_icon"
                  />
                )}
              </div>
            )}
            <div className="video_controls d-flex flex-column align-items-center justify-content-center">
              {!playing ? (
                <FontAwesomeIcon
                  icon={faPlay}
                  color="#ffffff"
                  onClick={() => playVideo()}
                  className="mb-2 video_control_icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faPause}
                  color="#ffffff"
                  onClick={() => playVideo()}
                  className="mb-2 video_control_icon"
                />
              )}
              <div className="slider_cont">
                <Slider
                  color="blue"
                  showLabelOnHover={false}
                  // value={currentTime}
                  // max={currentVideoData?.endTime}
                  // min={currentVideoData?.startTime}
                  value={SliderCurrentTime}
                  max={SliderMaxTime}
                  min={SliderMinTime}
                  label={null}
                  thumbSize={sliderThumbSize}
                  onMouseOver={() => {
                    if (playing) {
                      setSliderThumbSize(14);
                    }
                  }}
                  onMouseLeave={() => {
                    if (playing) {
                      setSliderThumbSize(0);
                    }
                  }}
                  onChange={(value) => handleSeek(value)}
                />
              </div>
              {/* <div className="video_edit_controls d-flex align-items-center justify-content-center">
                <div
                  className={`${
                    IsEditable ? "control" : "disabled"
                  } d-flex align-items-center justify-content-center   m-2 `}
                  onClick={() => combineVideo("up")}
                >
                  <FontAwesomeIcon icon={faAnglesUp} className={"me-2 "} />
                  <p>Combine</p>
                </div>
                <div
                  className={`${
                    IsEditable ? "control" : "disabled"
                  } d-flex align-items-center justify-content-center   m-2 `}
                  onClick={() => combineVideo("down")}
                >
                  <FontAwesomeIcon icon={faAnglesDown} className="me-2 " />
                  <p>Combine</p>
                </div>
                <div
                  className={`${
                    IsEditable ? "control" : "disabled"
                  } d-flex align-items-center justify-content-center   m-2 `}
                  onClick={() => splitVideo()}
                >
                  <FontAwesomeIcon icon={faSort} className="me-2  " />
                  <p>Split</p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="other_videos  ">
            {videoClips?.Clips?.map((currvideo, index) => (
              <div
                className={`m-2 other_video_cont d-flex align-items-center justify-content-end ${
                  JSON.stringify(currvideo) === JSON.stringify(video) &&
                  "choosen_video"
                }`}
                onClick={() => {
                  // setVideo(
                  //   `http://localhost:6969/get-clip?clipId=${video[0]._id}`
                  // );
                  console.log("video", currvideo);
                  setVideo(currvideo);
                  videoRef.current.load();
                  setPlaying(false);
                  setTrigger(!trigger);

                  setCurrentVideoData(currvideo[0]);
                  setCurrentVideoIndex(0);
                  setVideoIndex(index);
                  setSliderMinTime(currvideo[0]?.startTime);
                  setCurrentTime(currvideo[0]?.startTime);

                  setSliderCurrentTime(currvideo[0]?.startTime);
                }}
              >
                <div className="video_data d-flex flex-column align-items-center justify-content-between me-5 py-3 ">
                  <p className="video_position">
                    {" "}
                    {String.fromCharCode(65 + index)}
                  </p>
                </div>
                {video && (
                  <img
                    src={video[0]?.Thumbnail}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "images/no_image.png";
                    }}
                  />
                )}
                <FontAwesomeIcon
                  className="video_icon"
                  icon={faEllipsisVertical}
                />
                {/* <p>{video?.length}</p> */}
              </div>
            ))}
          </div>
        </div>
      </>
      {/* <div className="d-flex align-items-center justify-content-end mb-3 pe-3">
        <Button
          variant="filled"
          color="green"
          leftSection={<FontAwesomeIcon icon={faFloppyDisk} />}
          onClick={() => SaveTableHelper()}
        >
          Save
        </Button>
      </div> */}
      <div className="ag-theme-alpine-dark grid_cont mb-3 pe-3">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          singleClickEdit={true}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
          onCellValueChanged={() => setIsEditable(false)}
        />
      </div>
    </div>
  );
};

export default StudentVideoEditing;
