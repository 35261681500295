import React, { useState, useEffect } from "react";
import "./PlayerPracticeStyles.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import TeamSkelletonLoader from "./TeamSkelletonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { RingProgress, Text } from "@mantine/core";
const PlayerPractice = () => {
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Player`;
  const [PlayerPractices, setPlayerPractices] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPractices = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/getPlayerPractices`, {
        playerId: user?._id,
      });
      console.log(res.data);
      setPlayerPractices(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPractices();
  }, []);
  const [SelectedDate, setSelectedDate] = useState();
  return (
    <div className="player_practice_main">
      {!SelectedDate && <h3 className="mb-3">Practices</h3>}
      {!SelectedDate && !loading && (
        <>
          <div className="date_cont mt-3 px-3 d-flex flex-wrap">
            {PlayerPractices?.map((currdate, index) => {
              const date = new Date(currdate?.Date);
              const formattedDate = date.toISOString().split("T")[0];
              const monthYear =
                date.toLocaleString("default", { month: "long" }) +
                ", " +
                date.getFullYear().toString().substr(-2);
              const dayOfWeek = date.toLocaleString("default", {
                weekday: "long",
              });
              const day = date.getDate();
              return (
                <div
                  className={`date_box d-flex flex-column align-items-center justify-content-center m-2`}
                  onClick={() => setSelectedDate(currdate)}
                >
                  <div className="design_box"></div>
                  <div className="date_inner d-flex  align-items-start justify-content-start px-4">
                    <div className="d-flex flex-column align-items-start justify-content-center">
                      <p>{monthYear}</p>
                      <h1>{day}</h1>
                      <p>{dayOfWeek}</p>
                    </div>

                    <div className="card"></div>
                    <div className="card"></div>
                    <div className="card d-flex align-items-center justify-content-center">
                      <h4>{currdate?.PracticeStats?.length}</h4>
                    </div>
                  </div>
                  <div className="design_box"></div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {SelectedDate && !loading && (
        <>
          {SelectedDate?.PracticeStats && (
            <>
              {" "}
              <div className="stats_heading d-flex align-items-center justify-content-start px-4 py-3 mt-2  mb-2">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="me-3 back_arrow"
                    onClick={() => setSelectedDate(null)}
                  />
                  <h3>
                    {SelectedDate?.PracticeStats?.GeneralPosition}
                    {SelectedDate?.PracticeStats?.SubPosition
                      ? "-" + SelectedDate?.PracticeStats?.SubPosition
                      : ""}
                  </h3>
                </div>
              </div>
              <div className="Stats d-flex align-items-start justify-content-between flex-wrap pt-5">
                {Object.entries(SelectedDate?.PracticeStats?.Stats || {}).map(
                  ([key, value], index) => (
                    <div className="Editable_stats d-flex align-items-center justify-content-between px-2 mb-5">
                      <div className="d-flex align-items-center ">
                        <img
                          src="images/barchart.png"
                          alt=""
                          className="me-3"
                        />
                        <p>{key}</p>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="progress_cont">
                          <RingProgress
                            size={40}
                            thickness={4}
                            sections={[{ value: value * 10, color: "#009b3a" }]}
                          />
                        </div>

                        <p className=" number_stat mx-3">{value}</p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}
          {!SelectedDate?.PracticeStats && (
            <div className="Empty_cont d-flex flex-column align-items-center justify-content-center">
              <img src="images/empty.png" alt="" />
              <h1>No Practice Added</h1>
            </div>
          )}
        </>
      )}
      {loading && <TeamSkelletonLoader />}
    </div>
  );
};

export default PlayerPractice;
