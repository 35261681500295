import React, { useState, useEffect } from "react";
import "./PlayerTrainingStyles.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Progress } from "@mantine/core";
const PlayerTraining = () => {
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Player`;
  const [PlayerTrainings, setPlayerTrainings] = useState([]);
  const getTrainings = async () => {
    try {
      const res = await axios.post(`${API_URL}/getPlayerTrainings`, {
        playerId: user?._id,
      });
      console.log(res.data);
      setPlayerTrainings(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTrainings();
  }, []);
  return (
    <div className="player_training">
      {PlayerTrainings?.length > 0 && (
        <>
          <h3 className="mb-3">Trainings</h3>
          <div className="d-flex align-items-center flex-wrap">
            <>
              {PlayerTrainings.map((training) => {
                const date = new Date(training.Date);

                const monthNames = [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ];

                const dayNames = [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ];
                return (
                  <div
                    className={`user_training d-flex align-items-center justify-content-center mb-3 `}
                  >
                    <div className="d-flex flex-column align-items-start justify-content-center player_date ps-4">
                      <p className="small_text">
                        {monthNames[date.getMonth()]},{" "}
                        {date.getFullYear().toString().slice(-2)}
                      </p>
                      <p className="large_text">{date.getDate()}</p>
                      <p className="small_text">{dayNames[date.getDay()]}</p>
                      <div className="eclipse"></div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center attendance px-3">
                      <div className="d-flex align-items-center justify-content-between pb-2 top_attendance ">
                        <div className="d-flex flex-column align-items-start justify-content center me-4">
                          <p className="complimnetary"> Attendance</p>
                          <p className="value">
                            {" "}
                            {training?.TrainingObj?.Attendance
                              ? "Present"
                              : "Absent"}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content center ">
                          <p className="complimnetary"> Punctuality</p>
                          <p className="value">
                            {" "}
                            {training?.TrainingObj?.Punctual
                              ? "Punctual"
                              : "Not Punctual"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-2 ">
                        <div className="d-flex flex-column align-items-start justify-content center pe-3 me-5">
                          <p className="complimnetary"> Extra Time</p>
                          <p className="value">
                            {" "}
                            {training?.TrainingObj?.ExtraTime ? "Yes" : "No"}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content center">
                          <p className="complimnetary"> Film</p>
                          <p className="value">
                            {" "}
                            {training?.TrainingObj?.Film ? "Yes" : "No"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center metrics px-3">
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Effort</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {(training?.TrainingObj?.Effort / 5) * 100 + "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={(training?.TrainingObj?.Effort / 5) * 100}
                        />
                      </div>
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Coachable</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {(training?.TrainingObj?.Coachable / 5) * 100 + "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={(training?.TrainingObj?.Coachable / 5) * 100}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center metrics px-3">
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Body Language</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {" "}
                            {(training?.TrainingObj?.BodyLanguage / 5) * 100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={
                            (training?.TrainingObj?.BodyLanguage / 5) * 100
                          }
                        />
                      </div>
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Mental Toughness</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {(training?.TrainingObj?.MentalToughness / 5) *
                              100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={
                            (training?.TrainingObj?.MentalToughness / 5) * 100
                          }
                        />
                      </div>
                    </div>
                    <div
                      id="last_metrics"
                      className="d-flex flex-column align-items-center justify-content-center metrics  px-3"
                    >
                      <div className="d-flex flex-column   justify-content-center metric mb-2">
                        <p className="complimentary">Physical Toughness</p>
                        <div className="d-flex  justify-content-between metric_value">
                          <p className=" align-self-start"> 1-5</p>
                          <p className=" align-self-end highlight">
                            {" "}
                            {(training?.TrainingObj?.PhysicalToughness / 5) *
                              100 +
                              "%"}
                          </p>
                        </div>
                        <Progress
                          color="green"
                          value={
                            (training?.TrainingObj?.PhysicalToughness / 5) * 100
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </>
      )}
      {PlayerTrainings?.length == 0 && (
        <>
          <div className="Empty_cont d-flex flex-column align-items-center justify-content-center">
            <img src="images/empty.png" alt="" />
            <h1>No Training Added</h1>
          </div>
        </>
      )}
    </div>
  );
};

export default PlayerTraining;
