import React from "react";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import "../styles/ConfirmationStyles.scss";
const Confirmation = () => {
  const navigate = useNavigate();
  return (
    <div className="confirmation_cont d-flex flex-column align-items-center justify-content-center">
      <img src="/images/new-logo.png" alt="" id="logo" />
      <img src="images/thankyou_inst.png" alt="" className="gray_scale" />
      <h1 className="mb-5">Thank you for Signing up</h1>
      <h4 className="mb-5">
        Your details are being processed and the Input information is being
        <br /> verified. Please wait. After the verification is complete your{" "}
        <br />
        account will be created.
        <br />
        Thank you for choosing Athlantix{" "}
      </h4>
      <Button
        variant="outline"
        radius="md"
        className="mb-2"
        color="green"
        onClick={() => navigate("/chooseuser")}
      >
        Go back home
      </Button>
    </div>
  );
};

export default Confirmation;
