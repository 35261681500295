import React, { useEffect, useState } from "react";
import "../styles/InstituteDashStyles.scss";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../auth/authslice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faFutbol,
  faPeopleGroup,
  faBell,
  faGear,
  faArrowRightFromBracket,
  faMessage,
  faPeopleLine,
  faTriangleExclamation,
  faFolderClosed,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Messaging from "../components/Messaging";
import RegisteredTeams from "../components/RegisteredTeams";
import RegisteredPlayers from "../components/RegisteredPlayers";
import SearchInst from "../components/SearchInst";
import RegisteredCoaches from "../components/RegisteredCoaches";
import InstituteSettings from "../components/InstituteSettings";
import InstituteDashboard from "../components/InstituteDashboard";
import PlayerRequirements from "../components/PlayerRequirements";
import RequirementRequests from "../components/RequirementRequests";
import PlayerGradeSearch from "../components/PlayerGradeSearch";
import { Button } from "@mantine/core";
const InstituteDash = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (user === null) {
      navigate("/loginInstitute");
    } else {
      console.log(user);
    }
  }, [user]);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/chooseuser");
  };

  const [activeIcon, setActiveIcon] = useState(
    user?.CurrentSeason ? "Dashboard" : "Settings"
  );
  const [payment, setpayment] = useState(false);
  const [SidebarHidden, setSidebarHidden] = useState(false);
  return (
    <div className="Institute_cont ">
      {user?.isPaid && (
        <>
          {!SidebarHidden && (
            <div className="sidebar">
              <div className="sidebar_logo d-flex  align-items-center justify-content-center pt-4">
                <img
                  src="/images/new-logo.png"
                  alt=""
                  className="me-1"
                  onClick={() => setActiveIcon("Dashboard")}
                />
                {/* <h4>Athlantix</h4> */}
              </div>
              <div className="sidebar_cont   d-flex flex-column justify-content-between ">
                <div className="sidebar_cont_top d-flex flex-column justify-content-between">
                  {user?.isPaid && user?.CurrentSeason && (
                    <>
                      {" "}
                      <div
                        className={`icon_cont ${
                          activeIcon === "Dashboard" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Dashboard")}
                      >
                        <FontAwesomeIcon icon={faHouse} />
                        <p>Dashboard</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Players" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Players")}
                      >
                        <FontAwesomeIcon icon={faPeopleGroup} />
                        <p>Players</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Our Team" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Our Team")}
                      >
                        <FontAwesomeIcon icon={faPeopleLine} />
                        <p>Our Team</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Coaches" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Coaches")}
                      >
                        <FontAwesomeIcon icon={faFutbol} />
                        <p>Coaches</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Message" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Message")}
                      >
                        <FontAwesomeIcon icon={faMessage} />
                        <p>Message</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Requirements" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Requirements")}
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                        <p>Requirements</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Player Search" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Player Search")}
                      >
                        <FontAwesomeIcon icon={faFolderClosed} />
                        <p>Player Search</p>
                      </div>
                      <div
                        className={`icon_cont ${
                          activeIcon === "Requests" ? "active_nav" : ""
                        }`}
                        onClick={() => setActiveIcon("Requests")}
                      >
                        <FontAwesomeIcon icon={faBell} />
                        <p>Requests</p>
                      </div>
                    </>
                  )}
                  <div
                    className={`icon_cont ${
                      activeIcon === "Settings" ? "active_nav" : ""
                    }`}
                    onClick={() => setActiveIcon("Settings")}
                  >
                    <FontAwesomeIcon icon={faGear} />
                    <p>Settings</p>
                  </div>
                </div>
                <div className="sidebar_cont_bottom">
                  {" "}
                  <div className="icon_cont" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />{" "}
                    <p>Logout</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeIcon === "Dashboard" && <InstituteDashboard />}
          {activeIcon === "Players" && (
            <RegisteredPlayers
              hideSidebar={() => setSidebarHidden(!SidebarHidden)}
            />
          )}

          {activeIcon === "Coaches" && (
            <RegisteredCoaches
              hideSidebar={() => setSidebarHidden(!SidebarHidden)}
            />
          )}
          {activeIcon === "Our Team" && <RegisteredTeams />}
          {activeIcon === "Message" && (
            <div className="dasboard_main px-4 py-3 d-flex align-items-center justfy-content-end">
              <Messaging />
            </div>
          )}
          {activeIcon === "Settings" && <InstituteSettings />}
          {activeIcon === "Requirements" && <PlayerRequirements />}
          {activeIcon === "Requests" && <RequirementRequests />}
          {activeIcon === "Player Search" && <PlayerGradeSearch />}
        </>
      )}
      {!user?.isPaid && (
        <>
          {!payment ? (
            <div className="confirmation_cont d-flex flex-column align-items-center justify-content-center">
              <img src="/images/new-logo.png" alt="" id="logo" />
              {user?.ROS === null ? (
                <>
                  <img
                    src="images/Trial_ended.png"
                    alt=""
                    className="gray_scale"
                  />
                  <h1 className="mb-5">Your Trial has Expired</h1>
                  <h4 className="mb-5">
                    Your trial has expired. Pay now to continue using Athlantix.
                  </h4>
                </>
              ) : (
                <>
                  <img
                    src="images/payment_failed.png"
                    alt=""
                    className="gray_scale"
                  />
                  <h1 className="mb-5">Your Payment Request has Failed</h1>
                  <h4 className="mb-5">{user?.ROS}</h4>
                </>
              )}

              <div className="d-flex algn-items-center justift-content-between">
                <Button
                  variant="outline"
                  radius="md"
                  className="mb-2 me-4"
                  color="green"
                  onClick={() => setpayment(true)}
                >
                  Procceed to pay
                </Button>
                <Button
                  variant="outline"
                  radius="md"
                  className="mb-2 "
                  color="grey"
                  onClick={handleLogout}
                >
                  Go back home
                </Button>
              </div>
            </div>
          ) : (
            <InstituteSettings />
          )}
        </>
      )}
    </div>
  );
};

export default InstituteDash;
