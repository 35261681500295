import React, { useState } from "react";
import "./SettingsStyles.scss";
import { PasswordInput, Button } from "@mantine/core";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
const Settings = ({ type }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/`;
  const updatePassword = async () => {
    try {
      const res = await axios.post(`${API_URL + type}/update-password`, {
        oldPassword: oldPassword,
        newPassword: newPassword,
        id: user._id,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Password Changed Successfully</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Failed to Change Password</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  const passwordChangeHelper = () => {
    if (newPassword !== confirmPassword) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Passwords do not match</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        }
      );
      return;
    }
    if (oldPassword === newPassword) {
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">New Password cannot be same as old password</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        }
      );
      return;
    }
    updatePassword();
  };
  return (
    <div
      className="Settings_cont p-3"
      style={{ marginLeft: type === "Player" ? "15%" : "0" }}
    >
      {type === "Player" && (
        <div className="main_top mb-5">
          <h2>Settings</h2>
        </div>
      )}
      <div className="settings d-flex flex-column align-items-start justify-content-start">
        <h2>Change Password</h2>
        <p>Please enter your current password to change your password.</p>
        <div className="d-flex align-items-center justify-content-between password_cont mb-4">
          <p>Current Password</p>
          <div className="pasword_wrapper">
            <PasswordInput
              placeholder="Enter current password"
              size="lg"
              radius={"lg"}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between password_cont mb-4">
          <p>New Password</p>
          <div className="pasword_wrapper">
            <PasswordInput
              placeholder="Enter new password"
              size="lg"
              radius={"lg"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between password_cont mb-4">
          <p>Confirm New Password</p>
          <div className="pasword_wrapper">
            <PasswordInput
              placeholder="Confirm new password"
              size="lg"
              radius={"lg"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="button_cont d-flex align-items-center justify-content-center">
          <Button
            variant="gradient"
            radius={"lg"}
            gradient={{ from: "#009B3A", to: "#2FED76", deg: 180 }}
            onClick={passwordChangeHelper}
          >
            Update Password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
