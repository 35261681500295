import React, { useState, useEffect } from "react";
import { Button } from "@mantine/core";
import "../styles/DownloadStyles.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Loader } from "@mantine/core"; // Importing Loader for visual feedback

const port = process.env.REACT_APP_IP_ADDRESS;
const Video_API_URL = `${port}/api/videos/getDownloadLink`;

const Download = () => {
  const [loading, setLoading] = useState(false); // State to track loading status
  const [hasError, setHasError] = useState(false); // State to track if there's an error

  const sendCodeToEndpoint = async () => {
    setLoading(true); // Start loading
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const code = urlParams.get("Code");

    if (code) {
      try {
        const response = await axios.post(Video_API_URL, { Code: code });
        setLoading(false); // End loading
        toast.success("Download is starting!"); // Inform the user
      } catch (error) {
        setLoading(false); // End loading
        setHasError(true); // Set error flag to true
        toast.error("Failed to initiate download. Please try again."); // Inform the user
      }
    } else {
      setLoading(false); // End loading if no code found
      setHasError(true); // Set error flag to true
    }
  };

  useEffect(() => {
    sendCodeToEndpoint();
  }, []);

  const navigate = useNavigate();
  const navtodash = () => navigate("/chooseuser");

  return (
    <div className="home_cont">
      <div className="landing">
        <div id="elipse_1"></div>
        <div className="landing_top d-flex align-items-center justify-content-sm-between justify-content-center">
          <img src="/images/Vector.png" alt="logoimage" id="top_logo" />
          <Button
            variant="filled"
            size="lg"
            radius="xl"
            color="#0C0F21"
            onClick={navtodash}
            id="dashboard_btn"
          >
            Dashboard
          </Button>
        </div>
        <div className="landing_main d-flex flex-column align-items-center">
          {loading && <Loader size="xl" />}
          {hasError ? (
            <>
              <h1 className="headline">
                Oops! Your download link has expired.
              </h1>
              <Button
                variant="outline"
                size="md"
                onClick={sendCodeToEndpoint}
                style={{ marginTop: "20px" }}
              >
                Try Again
              </Button>
            </>
          ) : (
            <h1 className="headline">
              <span>Your download will begin shortly...</span>
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Download;
