import React, { useState, useEffect } from "react";
import "../styles/SignupStyles.scss";
import {
  Input,
  PasswordInput,
  NumberInput,
  FileInput,
  NativeSelect,
  Tooltip,
} from "@mantine/core";

import dayjs from "dayjs";
import { Button } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { login, reseter, register } from "../auth/authslice";
import { useNavigate, useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import axios from "axios";
const Signup = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filledFields, setFilledFields] = useState([]);
  const myArray = [
    "Email address",
    "Password",
    "Confirm Password",
    "First Name",
    "Middle name",
    "Last name",
    "Date of birth",
    "Age",
    "State",
    "Address",
    "Jersey Number",
    "Weight",
    "Height",
    "Year",
    "Image",
  ];
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const newArray = myArray.map((item) => ({
    value: item,
    type:
      item === "Password" || item === "Confirm Password"
        ? "password"
        : item === "Email address"
        ? "email"
        : item === "Personal Coaching"
        ? "check"
        : item === "Jersey Number"
        ? "jersey"
        : item === "Weight"
        ? "vit"
        : ["Height", "Age"].includes(item)
        ? "number"
        : item === "Date of birth"
        ? "date"
        : item === "Year"
        ? "Year"
        : item === "State"
        ? "state"
        : item === "Image"
        ? "Image"
        : item === "Image String"
        ? "Image String"
        : "text",
  }));
  const [inputValues, setInputValues] = useState(
    newArray.map((item) => ({
      ...item,
      input:
        item.type === "check"
          ? false
          : item.type === "Image"
          ? null
          : item.type === "date"
          ? dayjs().subtract(10, "year")
          : item.type === "Year"
          ? "Freshman"
          : item.type === "Image String"
          ? ""
          : "",
      error: false,
    }))
  );

  const showNextThree = () => {
    if (currentIndex + 3 < myArray.length) {
      const newInputValues = [...inputValues];
      newInputValues.slice(currentIndex, currentIndex + 3).forEach((item) => {
        if (item.value === "Middle name") {
          item.error = false;
        } else if (
          (item.type === "text" && item.input?.trim() === "") ||
          ((item.type === "date" || item.type === "password") &&
            item.input === "") ||
          (item.type === "Image" && item.input === null)
        ) {
          item.error = true;
        } else {
          item.error = false;
        }
      });

      setInputValues(newInputValues);
      const email = inputValues[0].input;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        // Set error for email input
        newInputValues[0].error = true;
        setInputValues(newInputValues);
        return;
      }
      const passwordItems = newInputValues.filter(
        (item) => item.type === "password"
      );

      if (
        passwordItems.length > 1 &&
        passwordItems[0].input !== passwordItems[1].input
      ) {
        passwordItems.forEach((item) => (item.error = true));
        toast.error("Passwords do not match", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }

      const allInputsValid = !newInputValues
        .slice(currentIndex, currentIndex + 3)
        .some((item) => item.error);

      if (!allInputsValid) {
        // alert("Values are missing");
        return;
      } else {
        setCurrentIndex(currentIndex + 3);
      }
    } else {
      const newInputValues = [...inputValues];
      newInputValues.slice(currentIndex, inputValues.length).forEach((item) => {
        if (
          (item.type === "text" && item.input?.trim() === "") ||
          ((item.type === "date" || item.type === "password") &&
            item.input === "") ||
          (item.type === "Image" && item.input === null)
        ) {
          item.error = true;
        } else {
          item.error = false;
        }
      });

      setInputValues(newInputValues);

      if (newInputValues.some((item) => item.error)) {
        return; // If there are any errors, stop execution
      }

      console.log(inputValues);
      const type = props.type;
      const email = inputValues[0].input;
      const password = inputValues[1].input;
      const userData = {
        email,
        password,
        type,
        updates: {
          email: inputValues[0].input,
          password: inputValues[1].input,
          firstName: inputValues[3].input,
          middleName: inputValues[4].input,
          lastName: inputValues[5].input,
          DOB: inputValues[6].input,
          age: inputValues[7].input,
          state: inputValues[8].input,
          address: inputValues[9].input,
          jerseyNumber: inputValues[10].input,
          weight: inputValues[11].input,
          year: inputValues[13].input,
          inches: inches,
          feet: feet,
          Image: ImageString,
        },
      };
      dispatch(register(userData));
    }
  };
  useEffect(() => {
    if (user) {
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Signup Successfull</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      navigate("/dashboard");
    }
  }, [user]);

  useEffect(() => {
    if (isError) {
      if (message == 403) {
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">User With this email already Exists</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      if (message == 404 || message == 500 || message == 502) {
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Server Under Maintainance</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else {
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Something Went Wrong</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      console.log(message, "message");
    }
  }, [isError]);

  const [feet, setfeet] = useState(0);
  const [inches, setinches] = useState(0);
  const showPreviousThree = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3);
    }
  };

  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/`;

  //   console.log(inputValues);
  //   try {
  //     const response = await axios.post(`${API_URL}${type}/update-info`, {
  //       id: user._id,
  //       updates: {
  //         email: inputValues[0].input,
  //         password: inputValues[1].input,
  //         firstName: inputValues[3].input,
  //         middleName: inputValues[4].input,
  //         lastName: inputValues[5].input,
  //         DOB: inputValues[6].input,
  //         age: inputValues[7].input,
  //         state: inputValues[8].input,
  //         address: inputValues[9].input,
  //         jerseyNumber: inputValues[10].input,
  //         weight: inputValues[11].input,
  //         height: inputValues[12].input,
  //         Image: inputValues[14].input,
  //       },
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     if (type == "Player") {
  //       navigate("/loginPlayer");
  //     }
  //     if (type == "Scout") {
  //       navigate("/loginscout");
  //     }
  //     if (type == "Coach") {
  //       navigate("/loginCoach");
  //     }
  //   }
  // };
  // useEffect(() => {
  //   console.log(user);
  //   if (user != null) {
  //     updateinfo();
  //   }
  // }, [user]);
  const [ImageString, setImageString] = useState();
  const handleimageUpladod = (event, actualIndex) => {
    console.log(event, "event");
    const newInputValues = [...inputValues];
    newInputValues[actualIndex].input = event;
    const file = event;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64 = reader.result;
        setImageString(base64);
      };
      reader.readAsDataURL(file);
    }

    setInputValues(newInputValues);
  };
  const [ImageError, setImageError] = useState(false);
  return (
    <div id="Signup_cont">
      <div className="top_cont d-flex  align-items-center justify-content-center pt-4">
        <img
          src="images/new-logo.png"
          alt=""
          className="me-1"
          onClick={() => navigate("/chooseuser")}
        />
      </div>
      <div className="form_cont">
        <h3>Sign up</h3>
        <div className="signup_form d-flex flex-column justify-content-between">
          <div>
            {newArray
              .slice(currentIndex, currentIndex + 3)
              .map((item, index) => {
                const actualIndex = currentIndex + index;
                return (
                  <>
                    {item.value !== "Height" && (
                      <Input.Wrapper
                        key={index}
                        label={
                          item.value !== "Image String"
                            ? item.value === "Weight"
                              ? item.value + " (lbs)"
                              : item.value
                            : ""
                        }
                        description=""
                        className="mb-4"
                        size="md"
                        error={
                          inputValues[actualIndex].error &&
                          (item.type === "password"
                            ? "Passwords do not match"
                            : item.type === "email" &&
                              inputValues[actualIndex].input !== ""
                            ? "Invalid email format"
                            : "This field cannot be empty")
                        }
                      >
                        {item.type === "email" && (
                          <Tooltip
                            label="Please enter a valid email address (e.g., example@example.com)"
                            position="right"
                          >
                            <Input
                              placeholder={item.value}
                              variant="filled"
                              size="lg"
                              radius="md"
                              value={inputValues[actualIndex].input}
                              onChange={(e) => {
                                const newInputValues = [...inputValues];
                                newInputValues[actualIndex].input =
                                  e.target.value;
                                setInputValues(newInputValues);
                              }}
                            />
                          </Tooltip>
                        )}
                        {item.type === "text" && (
                          <Input
                            placeholder={item.value}
                            variant="filled"
                            size="lg"
                            radius="md"
                            value={inputValues[actualIndex].input}
                            onChange={(e) => {
                              const newInputValues = [...inputValues];
                              newInputValues[actualIndex].input =
                                e.target.value;
                              setInputValues(newInputValues);
                            }}
                          />
                        )}
                        {item.type === "jersey" && (
                          <Input
                            placeholder={item.value}
                            variant="filled"
                            type="number"
                            size="lg"
                            radius="md"
                            value={inputValues[actualIndex].input}
                            onChange={(e) => {
                              const newInputValues = [...inputValues];
                              newInputValues[actualIndex].input =
                                e.target.value;
                              setInputValues(newInputValues);
                            }}
                          />
                        )}
                        {item.type === "vit" && (
                          <Input
                            placeholder={item.value}
                            variant="filled"
                            type="number"
                            size="lg"
                            radius="md"
                            value={inputValues[actualIndex].input}
                            onChange={(e) => {
                              const newInputValues = [...inputValues];
                              newInputValues[actualIndex].input =
                                e.target.value;
                              setInputValues(newInputValues);
                            }}
                          />
                        )}
                        {item.type === "password" && (
                          <Tooltip
                            label="Password must be 8 characters long  and contain alphanumeric characters"
                            position="right"
                          >
                            <PasswordInput
                              placeholder="Enter Password"
                              size="lg"
                              radius="md"
                              name="password"
                              value={inputValues[actualIndex]?.input}
                              onChange={(e) => {
                                const newInputValues = [...inputValues];
                                newInputValues[actualIndex].input =
                                  e.target.value;
                                setInputValues(newInputValues);
                              }}
                            />
                          </Tooltip>
                        )}
                        {item.type === "number" && (
                          <NumberInput
                            placeholder={item?.value}
                            variant="filled"
                            size="lg"
                            radius="md"
                            value={inputValues[actualIndex]?.input}
                            allowNegative={false}
                            allowDecimal={false}
                            onChange={(e) => {
                              if (!item?.value == "Age") {
                                const newInputValues = [...inputValues];
                                newInputValues[actualIndex].input = e;
                                setInputValues(newInputValues);
                              }
                            }}
                          />
                        )}
                        {item.type === "date" && (
                          <div className="date_picker_cont">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={inputValues[actualIndex]?.input}
                                disableFuture
                                maxDate={dayjs().subtract(10, "year")}
                                defaultValue={dayjs().subtract(10, "year")}
                                onChange={(newvalue) => {
                                  const newInputValues = [...inputValues];
                                  newInputValues[actualIndex].input = newvalue;
                                  newInputValues[7].input = dayjs().diff(
                                    newvalue,
                                    "year"
                                  );
                                  setInputValues(newInputValues);
                                }}
                                sx={{
                                  ".Mui-selected": {
                                    backgroundColor: "#009B3A !important",
                                  },
                                  ".MuiInputBase-root ": {
                                    borderRadius: "10px",

                                    width: "100%",
                                    color: "#cdcdcd",
                                  },
                                  "MuiTextField-root": {
                                    width: "100%",
                                  },
                                  ".MuiFormControl-root": {
                                    border: "none",
                                    width: "100% !important",
                                  },
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                    backgroundColor: "rgba(57, 60, 82, 0.2)",
                                    width: "100%",
                                  },
                                  ".MuiDateCalendar-root": {
                                    color: "#ffffff",
                                    padding: "5%",
                                    borderRadius: "16px",
                                    backgroundColor: "rgba(57, 60, 82, 0.2)",
                                  },
                                  ".MuiPickersToolbar-root": {
                                    color: "#ffffff",

                                    backgroundColor: "#101222",
                                  },
                                  ".MuiPickersCalendarHeader-root": {
                                    backgroundColor: "rgba(57, 60, 82, 0.2)",
                                    borderRadius: "16px",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                  },
                                  ". MuiPickersLayout-root": {
                                    backgroundColor: "#101222",
                                    color: "#ffffff",
                                  },
                                  ".MuiPickersLayout-contentWrapper": {
                                    backgroundColor: "#101222",
                                    color: "#ffffff",
                                  },
                                  ".MuiDialogActions-root": {
                                    backgroundColor: "#101222",
                                    color: "#ffffff",
                                    display: "none",
                                  },
                                  ".MuiDayCalendar-weekDayLabel": {
                                    color: "#ffffff",
                                  },
                                  ".MuiPickersDay-root": {
                                    color: "#ffffff",
                                    backgroundColor: "#101222",
                                  },
                                  ".MuiButtonBase-root": {
                                    color: "#ffffff",
                                  },
                                  ".Mui-selected": {
                                    backgroundColor: "#009B3A !important",
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        )}
                        {item.type === "state" && (
                          <NativeSelect
                            placeholder="Select state"
                            size="lg"
                            radius="md"
                            styles={{
                              label: { color: "#cbd5e1" }, // Change 'blue' to whatever color you prefer
                            }}
                            value={inputValues[actualIndex].input}
                            onChange={(e) => {
                              const newInputValues = [...inputValues];
                              newInputValues[actualIndex].input =
                                e.target.value;
                              setInputValues(newInputValues);
                            }}
                            id="state"
                            data={[
                              "Select a state",
                              "Alabama",
                              "Alaska",
                              "Arizona",
                              "Arkansas",
                              "California",
                              "Colorado",
                              "Connecticut",
                              "Delaware",
                              "Florida",
                              "Georgia",
                              "Hawaii",
                              "Idaho",
                              "Illinois",
                              "Indiana",
                              "Iowa",
                              "Kansas",
                              "Kentucky",
                              "Louisiana",
                              "Maine",
                              "Maryland",
                              "Massachusetts",
                              "Michigan",
                              "Minnesota",
                              "Mississippi",
                              "Missouri",
                              "Montana",
                              "Nebraska",
                              "Nevada",
                              "New Hampshire",
                              "New Jersey",
                              "New Mexico",
                              "New York",
                              "North Carolina",
                              "North Dakota",
                              "Ohio",
                              "Oklahoma",
                              "Oregon",
                              "Pennsylvania",
                              "Rhode Island",
                              "South Carolina",
                              "South Dakota",
                              "Tennessee",
                              "Texas",
                              "Utah",
                              "Vermont",
                              "Virginia",
                              "Washington",
                              "West Virginia",
                              "Wisconsin",
                              "Wyoming",
                            ]}
                          />
                        )}
                        {item.type === "Year" && (
                          <NativeSelect
                            placeholder="Year"
                            size="lg"
                            radius="md"
                            id="year"
                            value={inputValues[actualIndex]?.input}
                            onChange={(e) => {
                              const newInputValues = [...inputValues];
                              newInputValues[actualIndex].input =
                                e.currentTarget.value;
                              setInputValues(newInputValues);
                            }}
                            data={[
                              "Select year",
                              "Freshman",
                              "Sophomore",
                              "Junior",
                              "Senior",
                              "FifthGrade",
                              "SixthGrade",
                              "SeventhGrade",
                              "EighthGrade",
                            ]}
                          />
                        )}
                        {item.type === "Image" && (
                          <FileInput
                            error={
                              ImageError && "File Size Cannot Exceed 500Kb"
                            }
                            value={inputValues[actualIndex]?.input}
                            placeholder="Choose Image"
                            accept="image/png,image/jpeg,image/jpg"
                            onChange={(newvalue) => {
                              inputValues[actualIndex].error = false;
                              setImageError(false);
                              if (newvalue && newvalue.size > 500000) {
                                // 1MB
                                console.log(newvalue.size, "image size");
                                setImageError(true);
                                return;
                              }
                              console.log(newvalue);
                              handleimageUpladod(newvalue, actualIndex);
                            }}
                          />
                        )}
                      </Input.Wrapper>
                    )}
                    {item.value === "Height" && (
                      <div className="d-flex">
                        <Input.Wrapper
                          key={index}
                          label={"Feet"}
                          description=""
                          className="mb-4 me-3  "
                          size="md"
                          error={
                            inputValues[actualIndex].error &&
                            "This field cannot be empty"
                          }
                        >
                          <NumberInput
                            placeholder={item?.value}
                            variant="filled"
                            size="lg"
                            radius="md"
                            value={feet}
                            onChange={(e) => {
                              setfeet(e);
                            }}
                          />
                        </Input.Wrapper>
                        <Input.Wrapper
                          key={index}
                          label={"Inches"}
                          description=""
                          className="mb-4"
                          size="md"
                          error={
                            inputValues[actualIndex].error &&
                            "This field cannot be empty"
                          }
                        >
                          <NumberInput
                            placeholder={item?.value}
                            variant="filled"
                            size="lg"
                            radius="md"
                            value={inches}
                            onChange={(e) => {
                              setinches(e);
                            }}
                          />
                        </Input.Wrapper>
                      </div>
                    )}
                  </>
                );
              })}
          </div>

          <div className="button_cont d-flex align-items-center  justify-content-end">
            <Button
              variant="outline"
              color="rgba(255, 255, 255, 0.6) "
              className="me-3"
              onClick={showPreviousThree}
            >
              Back
            </Button>
            <Button
              variant="filled"
              color="rgba(0, 155, 58, 1)"
              onClick={showNextThree}
              loading={isLoading}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
