import "./PlayerRequirementsStyles.scss";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import {
  faMagnifyingGlass,
  faBell,
  faCircleInfo,
  faCirclePlus,
  faBookmark,
  faArrowLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Select } from "@mantine/core";
import { Button } from "@mantine/core";
import TeamSkelletonLoader from "./TeamSkelletonLoader";
import { Textarea } from "@mantine/core";
import { TagsInput } from "@mantine/core";
const PlayerRequirements = () => {
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/users/Institute`;
  const [teamName, setteamName] = useState("");
  const [team, setteam] = useState();
  const [players, setPlayers] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPlayers();
  }, []);

  const getPlayers = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/getAllPlayersFromInstitute`,
        {
          instituteId: user?._id,
        }
      );
      console.log("all players from inst", response.data);
      setPlayers(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const [selectedRequirementcategory, setselectedRequirementcategory] =
    useState();
  const getRequirements = async (
    Selectedcategory,
    updateSeletedRequirement
  ) => {
    setselectedRequirementcategory(Selectedcategory);
    console.log(selectedPalyer?._id, Selectedcategory);
    try {
      const response = await axios.post(
        `${port}/api/Requirements/getPlayerReqbyType`,
        {
          playerId: selectedPalyer?._id,
          reqType: Selectedcategory,
        }
      );
      console.log("all players from inst", response.data);
      setPlayerRequirements(response.data);
      if (updateSeletedRequirement) {
        response.data?.map((req) => {
          if (req?.reqHeading == selectedRequirement?.reqHeading) {
            setSelectedRequirement(req);
            return;
          }
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const mapToSpace = {
    Fulfilled: "Fulfilled",
    PartiallyFulfilled: "Partially Fulfilled",
    UnFulfilled: "Un Fulfilled",
  };

  const mapToNoSpace = {
    Fulfilled: "Fulfilled",
    "Partially Fulfilled": "PartiallyFulfilled",
    "Un Fulfilled": "UnFulfilled",
  };

  // Convert to spaced version
  const spaced = ["Fulfilled", "PartiallyFulfilled", "UnFulfilled"].map(
    (item) => mapToSpace[item]
  );

  // Convert back to no space version
  const noSpace = spaced.map((item) => mapToNoSpace[item]);
  const [updateRequestLoading, setUpdateRequestLoading] = useState(false);
  const updateRequirement = async () => {
    setUpdateRequestLoading(true);
    try {
      const response = await axios.post(
        `${port}/api/Requirements/updatePlayerReqbyAdmin`,
        {
          playerId: selectedPalyer?._id,
          reqHeading: selectedRequirement?.reqHeading,
          status: mapToNoSpace[NewStatus],
          adminComment: comment,
        }
      );
      console.log(response.data);
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Requirement Updated Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      getRequirements(selectedRequirementcategory, true);
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Error in Updating Requirement</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setcomment("");
      setUpdateRequestLoading(false);
    }
  };
  const [playerRequirements, setPlayerRequirements] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState();
  const selectTeam = (team) => {
    setteam(team?.players);
    setteamName(team?.name);
  };

  //player
  const [selectedPalyer, setSelectedPlayer] = useState();
  useEffect(() => {
    console.log(selectedPalyer);
  }, [selectedPalyer]);
  const [opened, { open, close }] = useDisclosure(false);
  const [category, setcategory] = useState();

  const [requirementmodal, setrequirementmodal] = useState(false);
  const [comment, setcomment] = useState("");

  const goback = () => {
    if (requirementmodal) {
      setSelectedRequirement();
      setrequirementmodal(false);
    } else if (category) {
      setcategory();
    } else {
      setteam();
      setteamName();
      setSelectedPlayer();
      setcategory();
    }
  };

  useEffect(() => {
    console.log("Selected player", selectedPalyer);
  }, [selectedPalyer]);
  const [NewStatus, setNewStatus] = useState();

  return (
    <div className="dashboard_content px-4 py-3">
      <div className="main_top d-flex justify-content-end align-items-center">
        <div className="main_top_right d-flex justify-content-between align-items-center ">
          <div className="notification_cont">
            <FontAwesomeIcon icon={faBell} size="xl" />
          </div>
          <Avatar color="cyan" radius="xl">
            {/* <img src="images/team-logo.png" alt="" /> */}
            <img className="user_image" src={user?.Image} alt="" />
          </Avatar>
        </div>
      </div>
      <div className="playerRequirements_cont">
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center">
            {teamName && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-3 back_icon"
                onClick={goback}
              />
            )}
            {!category && teamName && <h5>Registered Players</h5>}
            {!category && !teamName && !requirementmodal && (
              <h5>Choose Team to Select Player</h5>
            )}
            {category && !requirementmodal && <h5>{category}</h5>}
            {requirementmodal && <h5>Back</h5>}
          </div>

          {requirementmodal && (
            <Button
              variant="filled"
              size="md"
              color="#181A2C"
              leftSection={<FontAwesomeIcon icon={faPlus} />}
              onClick={updateRequirement}
              loading={updateRequestLoading}
            >
              Save
            </Button>
          )}
        </div>
        {!loading && !teamName && !category && !requirementmodal && (
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            {players.map((player) => (
              <div
                className="Team_box m-2 d-flex flex-column align-items-center justify-content-center"
                onClick={() => selectTeam(player)}
              >
                {/* <img src="images/team-logo.png" alt="" /> */}
                <img className="user_image" src={user?.Image} alt="" />
                <div className="design_box d-flex align-items-center justify-content-center">
                  <h4>{player?.name}</h4>
                </div>
              </div>
            ))}
          </div>
        )}
        {!loading && teamName && !category && !requirementmodal && (
          <div className="d-flex align-items-center justify-content-between mt-3 flex-wrap">
            {team?.map((player) => (
              <div
                className={`Player d-flex align-items-center justify-content-cenetr px-4 pb-4 pt-2 `}
                onClick={() => {
                  setSelectedPlayer(player);
                  open();
                }}
              >
                <div className="design_box"></div>
                {selectedPalyer?._id == player?._id && (
                  <div className="selected_player_glow"></div>
                )}

                <img className="player_image pe-2" src={player?.Image} alt="" />
                <div className="d-flex flex-column align-items-start justify-content-end">
                  <h4 className="mb-3">
                    {player?.firstName + " " + player?.lastName}
                  </h4>
                  <p className="contrast">{}</p>
                  <div className="d-flex align-items-center justify-content-between fullwidth mt-2">
                    <div className="d-flex flex-column">
                      <p className="contrast mb-2 ">Weight</p>
                      <p>{player?.weight + " lbs"}</p>
                    </div>
                    <div className="d-flex flex-column">
                      <p className="contrast mb-2 ">Height</p>
                      <p>{player?.feet + " ft " + player?.inches + '"'}</p>
                    </div>
                  </div>
                </div>
                <div className="jersey d-flex flex-column align-items-center justify-content-center ">
                  <p className="jersey_number">{player?.jerseyNumber}</p>
                  <p className="player_position"></p>
                  {player?.positionsPlayed?.length > 0 && (
                    <div className="d-flex align-items-center">
                      <img src="images/barchart.svg" alt="" className="me-2" />
                      <p className="contrast">{player?.positionsPlayed[0]}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {loading && <TeamSkelletonLoader />}
        {category && !requirementmodal && (
          <>
            <div className="category_top px-3 pt-2 mt-3">
              <div className="row">
                <div className="col-7">
                  <p className="heading">Requirements</p>
                </div>
                <div className="col-2">
                  <p className="heading">Label</p>
                </div>
                <div className="col-2">
                  <p className="heading">Status</p>
                </div>
              </div>
            </div>

            <div className="requirement_cont">
              {playerRequirements?.map((requirement) => (
                <div className="requirement d-flex align-items-center ">
                  <div className="row">
                    <div className="col-7 ">
                      <h5>{requirement?.reqHeading}</h5>
                      <p>{requirement?.reqDescription}</p>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-start align-items-center">
                      {requirement?.reqTags?.map((tag) => (
                        <div className="label d-flex align-items-center justify-content-start px-3 mb-2">
                          <img src="images/label.png" alt="" className="me-3" />
                          <p>{tag}</p>
                        </div>
                      ))}
                    </div>
                    <div className="col-2 d-flex justify-content-start align-items-center">
                      <div className="status d-flex align-items-center justify-content-start px-2">
                        <div className="circle me-3"></div>
                        <p>{mapToSpace[requirement?.status]}</p>
                      </div>
                    </div>
                    <div className="col-1 d-flex align-items-center justify-content-end">
                      <img
                        className="edit_req"
                        src="images/Edit.png"
                        alt=""
                        onClick={() => {
                          setrequirementmodal(true);
                          setSelectedRequirement(requirement);
                          setNewStatus(mapToSpace[requirement?.status]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {requirementmodal && (
          <>
            <div className="bordered_text p-2 mb-4 d-flex align-items-center justify-content-center mt-3">
              <h3>
                {selectedPalyer?.firstName + " " + selectedPalyer?.lastName}
              </h3>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="label_name d-flex align-items-center justify-content-start ">
                <div className="bordered_text p-2 px-3 mb-2 me-3 d-flex align-items-center justify-content-center">
                  <h3>{selectedRequirement?.reqHeading}</h3>
                </div>
                {selectedRequirement?.reqTags?.map((tag) => (
                  <div className="label d-flex align-items-center justify-content-start px-3 me-2">
                    <img src="images/label.png" alt="" className="me-3" />
                    <p>{tag}</p>
                  </div>
                ))}
              </div>
              <Select
                label={<h6 className="mb-2">Status</h6>}
                defaultValue={NewStatus}
                onChange={setNewStatus}
                data={["Fulfilled", "Partially Fulfilled", "Un Fulfilled"]}
              />
              {/* <div className="status d-flex align-items-center justify-content-start px-2 ">
                <div className="circle me-3"></div>
                <p>{selectedRequirement?.status}</p>
              </div> */}
            </div>
            <h5 className="mt-3">Description :</h5>
            <div className="bordered_text_desc p-3">
              <p>{selectedRequirement?.reqDescription}</p>
            </div>
            <Textarea
              label={<h5 className="mt-3 mb-3">Comment :</h5>}
              placeholder="Add Comments"
              radius={"md"}
              autosize
              minRows={5}
              value={comment}
              onChange={(e) => setcomment(e.target.value)}
            />
            <h5 className="mt-3">Comments :</h5>
            <div className="comments mt-3 mb-3 py-2 px-2">
              {selectedRequirement?.playerComment?.map((comment) => (
                <div className="comment mb-2 fullwidth">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center justify-content-center">
                      <div className="profile_circle"></div>
                    </div>
                    <div className="col-8 d-flex flex-column user_comment ">
                      <h6>Player</h6>
                      <p>{comment}</p>
                      <p className="date">22:00 22/12/12</p>
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-between">
                      <img src="images/dislike.png" alt="" />
                      <img
                        src="images/dislike.png"
                        alt=""
                        style={{ transform: "rotate(180deg)" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {selectedRequirement?.adminComment?.map((comment) => (
                <div className="comment mb-2 fullwidth">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center justify-content-center">
                      <div className="profile_circle"></div>
                    </div>
                    <div className="col-8 d-flex flex-column user_comment ">
                      <h6>Institute</h6>
                      <p>{comment}</p>
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-between">
                      <img src="images/dislike.png" alt="" />
                      <img
                        src="images/dislike.png"
                        alt=""
                        style={{ transform: "rotate(180deg)" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size="xl"
        id="choose_requirement_modal"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className="requirement_modal_cont d-flex flex-column align-items-center">
          <h5>Select Requirements Category</h5>

          <div className="requirement_categories d-flex align-items-center justify-content-between">
            <div
              className="requirement d-flex flex-column align-items-center justify-content-center "
              onClick={() => {
                setcategory("Academic");
                getRequirements("Edu");
                close();
              }}
            >
              <div className="glow"></div>
              <img className="mb-2" src="images/Cap.png" alt="" />
              <h5>Academic</h5>
            </div>
            <div
              className="requirement d-flex flex-column align-items-center justify-content-center "
              onClick={() => {
                setcategory("Non-Academic");
                getRequirements("CoEdu");
                close();
              }}
            >
              <div className="glow"></div>
              <img className="mb-2" src="images/Rugby.svg" alt="" />
              <h5>Non-Academic</h5>
            </div>
            <div
              className="requirement d-flex flex-column align-items-center justify-content-center "
              onClick={() => {
                setcategory("Other");
                getRequirements("Other");
                close();
              }}
            >
              <div className="glow"></div>
              <img className="mb-2" src="images/other.png" alt="" />
              <h5>Other</h5>
            </div>
          </div>
        </div>
      </Modal>
      {/* <Modal
        opened={requirementmodal}
        onClose={() => setrequirementmodal(false)}
        centered
        size="xl"
        id="requirement_modal"
        overlayProps={{
          backgroundOpacity: 0.3,
          blur: 3,
        }}
      >
        <div className="player_requirement_cont d-flex flex-column px-5">
          <h4 className="mb-3">
            {selectedPalyer?.firstName + " " + selectedPalyer?.lastName}
          </h4>

          <div className="d-flex  align-items-center justify-content-between fullwidth mb-3">
            <div className="d-flex">
              <h5>Name</h5>
              <div className="label d-flex align-items-center justify-content-start ms-3 px-3">
                <img src="images/label.png" alt="" className="me-3" />
                <p>Label</p>
              </div>
            </div>
            <div className="status d-flex align-items-center justify-content-start px-3">
              <div className="circle me-3"></div>
              <p>Partially Fulfilled</p>
            </div>
          </div>
          <h6>Description:</h6>
          <p className="desc">{selectedRequirement?.reqDescription}</p>
          <h6>Comments (3) :</h6>

          <div className="comments py-2 px-2">
            <div className="comment mb-2 fullwidth">
              <div className="row">
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <div className="profile_circle"></div>
                </div>
                <div className="col-8 d-flex flex-column user_comment ">
                  <h6>User</h6>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </p>
                  <p className="date">22:00 22/12/12</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-between">
                  <img src="images/dislike.png" alt="" />
                  <img
                    src="images/dislike.png"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              </div>
            </div>
            <div className="comment mb-2 fullwidth">
              <div className="row">
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <div className="profile_circle"></div>
                </div>
                <div className="col-8 d-flex flex-column user_comment ">
                  <h6>User</h6>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </p>
                  <p className="date">22:00 22/12/12</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-between">
                  <img src="images/dislike.png" alt="" />
                  <img
                    src="images/dislike.png"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              </div>
            </div>
            <div className="comment mb-2 fullwidth">
              <div className="row">
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <div className="profile_circle"></div>
                </div>
                <div className="col-8 d-flex flex-column user_comment ">
                  <h6>User</h6>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </p>
                  <p className="date">22:00 22/12/12</p>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-between">
                  <img src="images/dislike.png" alt="" />
                  <img
                    src="images/dislike.png"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default PlayerRequirements;
