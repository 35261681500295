import React, { useState, useEffect, useRef } from "react";
import "./VideoEditorStyles.scss";
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Progress } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { Slider } from "@mantine/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mantine/core";
import { toast } from "react-toastify";
import { Switch } from "@mantine/core";
//upload video
import { Group, Text, rem } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Input } from "@mantine/core";
import { Dropzone, DropzoneProps, MIME_TYPES } from "@mantine/dropzone";
import "@mantine/dropzone/styles.css";

const VideoEditor = ({
  hideSidebar = () => {},
  upload,
  setupload,
  setgoback,
  goBack,
  setTeamImp,
}) => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const port = process.env.REACT_APP_IP_ADDRESS;
  const API_URL = `${port}/api/`;
  const getTeams = async () => {
    try {
      const res = await axios.post(
        `${API_URL}users/Institute/getAllTeamsForInstitute`,
        {
          instituteId: user?.Institute,
        }
      );

      setTeams(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [videos, setVideos] = useState([]);
  const getVideos = async () => {
    try {
      const res = await axios.get(
        `${API_URL}Videos/videos/${user?.Institute}/${team}`
      );
      console.log("get videos", res);
      setVideos(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    if (team) {
      hideSidebar();
      getVideos();
    }
  }, [team]);

  const [video, setVideo] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const [uploadVideosrc, setuploadVideosrc] = useState(null);
  function createObjectURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve(URL.createObjectURL(new Blob([reader.result])));
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    Team1: "",
    Team2: "",
    InstTeam: "",
    DateOfMatch: dayjs(),
    InstituteId: user?.Institute,
    markforAICuts: false,
    markforVACuts: false,
  });
  const uploadvideo = async (files) => {
    const file = files[0];
    setFile(file);
    const url = await createObjectURL(file);
    setuploadVideosrc(url);
    open();
  };

  //go back
  useEffect(() => {
    if (goBack) {
      if (upload) {
        setVideo(null);
        setupload(false);
        setgoback(false);
        getVideos();
      } else {
        setTeam(null);
        setVideo(null);
        setupload(false);
        setgoback(false);

        hideSidebar();
      }
    }
  }, [goBack]);
  const [matchDate, setMatchDate] = useState(dayjs());
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const handleInputChange = (e) => {
    if (e.target.name == "markforAICuts" || e.target.name == "markforVACuts") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    console.log(formData);
    setFormData({
      ...formData,

      InstTeam: team,
    });
    const data = new FormData();

    data.append("file", file);
    Object.keys(formData).forEach((key) => data.append(key, formData[key]));

    sendVideo(data);
  };
  const [percentCompleted, setpercentCompleted] = useState(0);
  const config = {
    onUploadProgress: function (progressEvent) {
      setpercentCompleted((progressEvent.loaded / progressEvent.total) * 100);
      console.log(percentCompleted);
    },
  };
  const [isUploading, setisUploading] = useState(false);
  const sendVideo = async (data) => {
    try {
      setisUploading(true);

      if (formData.Team1 != "" && formData.Team2 != "" && file) {
        const res = await axios.post(
          `${API_URL}Videos/process-video`,
          data,
          config
        );
        console.log(res);
        toast.success(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Video Uploaded Successfully</p>
          </>,

          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        close();
        getVideos();
        setgoback(true);
      }
    } catch (error) {
      console.log(error);

      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Could not Upload Video</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } finally {
      setisUploading(false);
      setpercentCompleted(0);
    }
  };

  useEffect(() => {
    if (video) {
      navigate(`/videoeditor`, { state: { video: video } });
    }
  }, [video]);
  const deleteVideo = async (video) => {
    try {
      const res = await axios.post(`${API_URL}Videos/DeleteVideo`, {
        videoId: video._id,
      });
      toast.success(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Video Deleted Successfully</p>
        </>,

        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      getVideos();
    } catch (error) {
      console.log(error);
      toast.error(
        <>
          <img src="images/new-logo.png" className="toast_logo" />
          <p className="mt-3">Could not Delete Video</p>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  return (
    <div className="video_Editor_cont">
      {/* {team && !video && (
        <FontAwesomeIcon
          icon={faArrowLeft}
          color="#ffffff"
          size="lg"
          className="mt-4"
          onClick={() => {
            setTeam(null);
            hideSidebar();
            setupload(false);
          }}
        />
      )} */}
      {!upload && teams && !team && (
        <>
          <h2 style={{ color: "#ffffff" }}>Teams</h2>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            {teams?.map((team, index) => (
              <div
                className="Team_box m-2 d-flex flex-column align-items-center justify-content-center"
                onClick={() => {
                  setFormData({
                    ...formData,

                    InstTeam: team,
                  });
                  setTeam(team);
                  setTeamImp(team);
                }}
              >
                <img src="images/team-logo.png" alt="" />
                <div className="design_box d-flex align-items-center justify-content-center">
                  <h4 style={{ color: "#ffffff" }}>{team}</h4>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {!upload && team && (
        <div className="uploaded_videos_cont d-flex flex-wrap justify-content-start mt-3 pt-3 px-2">
          {videos.map((video, index) => {
            const date = new Date(video?.dateofmatch);

            const day = String(date.getUTCDate()).padStart(2, "0");
            const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based, so we need to add 1
            const year = String(date.getUTCFullYear()).slice(2); // Get the last two digits of the year

            const formattedDate = `${day}/${month}/${year}`;

            return (
              <div className="image_cont mx-2 my-3">
                <img
                  src={video?.Thumbnail}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "images/no_image.png";
                  }}
                  onClick={() => setVideo(video)}
                />
                <Badge color="rgba(115, 115, 115, 1)" className="date_badge">
                  {formattedDate}
                </Badge>
                <p className="video_title">{`${video?.team1} vs ${video?.team2}`}</p>

                <Popover width={130} position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <FontAwesomeIcon
                      className="video_icon"
                      icon={faEllipsisVertical}
                    />
                  </Popover.Target>
                  <Popover.Dropdown className="d-flex align-items-center">
                    <Button
                      variant="filled"
                      color="red"
                      leftSection={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => deleteVideo(video)}
                    >
                      Delete
                    </Button>
                  </Popover.Dropdown>
                </Popover>
              </div>
            );
          })}
        </div>
      )}

      {upload && (
        <div className="upload_video_cont pt-5  ">
          <Dropzone
            onDrop={(files) => uploadvideo(files)}
            onReject={(files) => console.log("rejected files", files)}
            // maxSize={5 * 1024 ** 2}
            accept={["video/mp4"]}
            // {...props}
          >
            <Group
              justify="center"
              gap="xl"
              mih={220}
              style={{ pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-green-6)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-red-6)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-dimmed)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Idle>

              <div>
                <Text size="xl" inline>
                  Drag Video here or click to select Video
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                  The File size should not exceed 10gb
                </Text>
              </div>
            </Group>
          </Dropzone>
        </div>
      )}

      <Modal opened={opened} onClose={close} size={"lg"} centered>
        <video width="100%" controls>
          <source src={uploadVideosrc} type="video/mp4" />
        </video>
        {!isUploading && (
          <>
            <div className="d-flex align-items-end justify-content-between  ">
              <Input.Wrapper label="Home Team Name" className="my-3">
                <Input
                  radius={"md"}
                  className="mt-2 team_name_input"
                  value={formData.Team1}
                  onChange={handleInputChange}
                  name="Team1"
                ></Input>
              </Input.Wrapper>
              <h4 className="mx-3 mb-4">vs</h4>

              <Input.Wrapper label="Away Team Name" className="my-3">
                <Input
                  radius={"md"}
                  className="mt-2 team_name_input"
                  value={formData.Team2}
                  onChange={handleInputChange}
                  name="Team2"
                ></Input>
              </Input.Wrapper>
            </div>
            <div className="d-flex align-items-center justify-content-between date_ai_assist">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formData.DateOfMatch}
                  onChange={(newvlue) => {
                    setFormData({ ...formData, DateOfMatch: newvlue });
                  }}
                  name="DateOfMatch"
                  sx={{
                    ".Mui-selected": {
                      backgroundColor: "#009B3A !important",
                    },
                    ".MuiInputBase-root ": {
                      borderRadius: "10px",

                      width: "100%",
                      color: "#cdcdcd",
                    },
                    "MuiTextField-root": {
                      width: "100%",
                    },
                    ".MuiFormControl-root": {
                      border: "none",
                      width: "100% !important",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      backgroundColor: "rgba(57, 60, 82, 0.2)",
                      width: "100%",
                    },
                    ".MuiDateCalendar-root": {
                      color: "#ffffff",
                      padding: "5%",
                      borderRadius: "16px",
                      backgroundColor: "rgba(57, 60, 82, 0.2)",
                    },
                    ".MuiPickersToolbar-root": {
                      color: "#ffffff",

                      backgroundColor: "#101222",
                    },
                    ".MuiPickersCalendarHeader-root": {
                      backgroundColor: "rgba(57, 60, 82, 0.2)",
                      borderRadius: "16px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    },
                    ". MuiPickersLayout-root": {
                      backgroundColor: "#101222",
                      color: "#ffffff",
                    },
                    ".MuiPickersLayout-contentWrapper": {
                      backgroundColor: "#101222",
                      color: "#ffffff",
                    },
                    ".MuiDialogActions-root": {
                      backgroundColor: "#101222",
                      color: "#ffffff",
                      display: "none",
                    },
                    ".MuiDayCalendar-weekDayLabel": {
                      color: "#ffffff",
                    },
                    ".MuiPickersDay-root": {
                      color: "#ffffff",
                      backgroundColor: "#101222",
                    },
                    ".MuiButtonBase-root": {
                      color: "#ffffff",
                    },
                    ".Mui-selected": {
                      backgroundColor: "#009B3A !important",
                    },
                  }}
                />
              </LocalizationProvider>
              <Switch
                name="markforAICuts"
                value={formData.markforAICuts}
                onChange={handleInputChange}
                label={
                  <div className="d-flex align-items-center">
                    <img
                      className="radio_image me-2"
                      src="images/magicpen.png"
                    />
                    <p className="m-0 radio_text ">AI Cut</p>
                  </div>
                }
              />
              <Switch
                name="markforVACuts"
                value={formData.markforVACuts}
                onChange={handleInputChange}
                // iconColor="#009b3a"
                // color="#232535"
                label={
                  <div className="d-flex align-items-center">
                    <img className="radio_image me-2" src="images/Vector.png" />
                    <p className="m-0 radio_text ">Athlantix Assist</p>
                  </div>
                }
              />
            </div>

            <div className="button_cont_modal d-flex align-items-center justify-content-center mt-3">
              <Button
                onClick={() => close()}
                className="me-3"
                radius={"xl"}
                color="red"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                radius={"xl"}
                color="green"
              >
                Submit
              </Button>
            </div>
          </>
        )}
        {isUploading && (
          <>
            <div className="d-flex align-items-center justify-content-center my-3">
              <h4>Uploading...</h4>
            </div>
            <Progress
              label={percentCompleted}
              value={percentCompleted}
              striped
              animated
            />
          </>
        )}
      </Modal>

      {}
    </div>
  );
};

export default VideoEditor;
